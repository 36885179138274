<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import LoginLayout from "./LoginLayout.vue";
import GlobalLoader from "./GlobalLoader.vue";
import DefaultLayout from "./DefaultLayout.vue";

const route = useRoute();
const layout = ref<any>(route.meta?.layout);
const layoutLoader = ref(true);

watch(route, () => {
  layout.value = route.meta?.layout;
  layoutLoader.value = false;
});

onMounted(() => {
  layoutLoader.value = true;
});
</script>

<template>
  <section class="min-h-[100vh] h-full bg-mainBg text-mainText">
    <GlobalLoader v-if="layoutLoader" />
    <LoginLayout v-if="layout === 'auth' && !layoutLoader" />
    <DefaultLayout v-if="!layout && !layoutLoader" />
    <section id="modal" />
  </section>
</template>
