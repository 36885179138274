<script setup lang="ts">
import { defineEmits, ref, computed } from "vue";

import type { BaseValidation } from "@/types/validation";

type Props = {
  name?: string;
  modelValue?: string | Date | number;
  type?: string;
  placeholder?: string;
  defaultBg?: boolean;
  customWidth?: string;
  disabled?: boolean;
  supportText?: string;
  validation?: BaseValidation;
  customHeight?: string;
  customHighlight?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  type: "text",
  customWidth: "200px",
  customHeight: "40",
});
/**
 * Variable for input type - password
 */
const isShowPassword = ref(false);
/**
 * computed for change input type if we have default type - password
 * if we have default type - any without password we return default type
 */
const currentInputType = computed(() => {
  if (props.type === "password") {
    return isShowPassword.value ? "text" : "password";
  }
  return props.type;
});
/**
 * Function for change input type if we have default type - password
 */
const changeInputType = () => {
  isShowPassword.value = !isShowPassword.value;
};
const emit = defineEmits<{
  (event: "update:modelValue", value: string): void;
  (event: "custonOnChange", data: Event): void;
}>();
function handleInput(e: Event) {
  const value = (e.target as HTMLInputElement).value;
  emit("update:modelValue", value);
}

const calculateIconRightPosition = () => {
  const currentHeight = +props.customHeight;

  if (currentHeight > 50 && !props.validation?.$error) {
    return "33%";
  }

  if (currentHeight > 50 && props.validation?.$error) {
    return "23%";
  }

  if (currentHeight < 50) {
    return "25%";
  }
};

const calculateIconLeftPosition = () => {
  const currentHeight = +props.customHeight;

  if (currentHeight > 50) {
    return "-1%";
  }

  if (currentHeight < 50) {
    return "-6%";
  }
};

const calcHeight = () => {
  if (props.type === "file") {
    return "40px";
  } else {
    return;
  }
};
</script>

<template>
  <div>
    <label class="label font-regular text-[12px]">{{ name }}</label>
    <div class="w-full flex relative flex-col">
      <span
        class="absolute left-[5%] h-full flex justify-center items-center"
        :style="{ top: calculateIconLeftPosition() }"
        :class="{
          'w-0': !$slots['icon-left'],
          'w-[25px]': $slots['icon-left'],
        }"
      >
        <slot name="icon-left" />
      </span>
      <input
        :type="currentInputType"
        class="general"
        :disabled="disabled"
        :value="modelValue"
        @input="handleInput"
        :placeholder="placeholder"
        v-bind="$attrs"
        :class="{
          'input-style': props.type !== 'file',
          'input-file': props.type === 'file',
          'left-slot': $slots['icon-left'],
          'right-slot': $slots['icon-right'] || props.type === 'password',
          errorBorder: validation?.$error || props.customHighlight,
          'cursor-pointer': type === 'date',
        }"
        :style="{ 'min-width': props.customWidth, height: calcHeight() }"
        @change="(event) => emit('custonOnChange', event)"
      />
      <span
        class="absolute right-[5%] w-[25px] flex justify-center items-center"
        :style="{ top: calculateIconRightPosition() }"
      >
        <slot name="icon-right" />
        <div v-if="props.type === 'password'" class="cursor-pointer">
          <span
            v-if="currentInputType === 'text'"
            @click="changeInputType"
            class="w-[20px] stroke-svgColor"
          >
            <feather-icon
              class="ml-1 cursor-pointer"
              type="eye-off"
              size="16"
            />
          </span>
          <span
            v-else
            @click="changeInputType"
            class="w-[20px] stroke-svgColor"
          >
            <feather-icon class="ml-1 cursor-pointer" type="eye" size="16" />
          </span>
        </div>
      </span>

      <span
        v-if="currentInputType === 'file' && !$slots['icon-right']"
        class="absolute right-[5%] top-[25%] flex justify-center items-center"
      >
        <span class="w-[20px] cursor-pointer"> PaperClipIcon </span>
      </span>

      <p
        v-if="supportText"
        class="absolute text-[10px] lg:bottom-[-20px] xl:bottom-[-20px] md:bottom-[-35px] sm:bottom-[-35px] left-[5px]"
      >
        {{ supportText }}
      </p>

      <span class="errorMessage" :class="{ showMessage: validation?.$error }">
        <p class="font-regular" v-if="validation?.$error">
          {{ validation?.$errors[0]?.$message }}
        </p>
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
input[type="file"]::file-selector-button {
  display: none;
}

.general {
  @apply transition-all duration-500 shadow-md bg-inputBg text-mainText hover:shadow-lg  outline-none focus:shadow-lg pl-[50px];
}

.input-style {
  @apply h-[40px] rounded-md px-4 w-full flex items-center font-light  border-2 border-transparent;
}

.left-slot {
  padding-left: 50px;
}

.right-slot {
  padding-right: 50px;
}

.input-file {
  @apply border-2 border-primary text-[0.8rem] p-[10px] cursor-pointer rounded-lg  flex items-center;
}

.errorBorder {
  transition: all 0.1s;
  @apply border-2 border-red-500;
}

.errorMessage {
  @apply opacity-0 z-[1] invisible text-red-500 text-[0.8rem] pt-1;
  transform: translateY(0px);
  transition:
    transform 0.8s ease,
    opacity 0.4s ease;
}

.showMessage {
  @apply opacity-100 visible;
  transform: translateY(5px);
}
</style>
