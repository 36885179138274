import axios from "axios";

export const TOKEN_TYPE = "Bearer";
export const ACCESS_TOKEN = "accessToken";
export const AUTH_HEADER = "Authorization";

const request = axios.create({
  baseURL: import.meta.env.VITE_API_URL
    ? `${import.meta.env.VITE_API_URL}`
    : "/api",
  ...(import.meta.env.VITE_API_URL && {
    headers: {
      "Access-Control-Allow-Origin": `${import.meta.env.VITE_API_URL}`,
    },
  }),
});
const refreshTokenIns = axios.create({
  baseURL: import.meta.env.VITE_API_URL
    ? `${import.meta.env.VITE_API_URL}`
    : "/api",
  ...(import.meta.env.VITE_API_URL && {
    headers: {
      "Access-Control-Allow-Origin": `${import.meta.env.VITE_API_URL}`,
    },
  }),
});

refreshTokenIns.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);

    if (accessToken) {
      const headers = config?.headers ?? {};
      if (headers) {
        config.headers = {
          [AUTH_HEADER]: `${TOKEN_TYPE} ${accessToken}`,
          ...config.headers,
        } as any;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

request.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);

    if (accessToken) {
      const headers = config?.headers ?? {};
      if (headers) {
        config.headers = {
          [AUTH_HEADER]: `${TOKEN_TYPE} ${accessToken}`,
          ...config.headers,
        } as any;
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

let isRefreshing: boolean = false;
let refreshQueue: any = [];

request.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (originalRequest.url === "/auth/login" && response.status === 401) {
      throw new Error(
        JSON.stringify({
          message: "Invalid credentials",
          status: 401,
        }),
      );
    }

    if (originalRequest.url === "/auth/password" && response.status === 401) {
      throw new Error(
        JSON.stringify({
          message: "Invalid credentials",
          status: 401,
        }),
      );
    }

    if (axios.isCancel(error)) {
      throw new Error(
        JSON.stringify({
          message: "canceled",
          status: "canceled",
        }),
      );
    } else {
      console.log("Error:", error);
    }

    if (response?.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;

        return refreshTokenIns
          .post("/auth/refresh", {}, { withCredentials: true })
          .then((resp) => {
            if (resp?.status === 201) {
              const newAccessToken = resp?.data?.access_token;
              localStorage.setItem(ACCESS_TOKEN, newAccessToken);
              request.defaults.headers.common[AUTH_HEADER] =
                `${ACCESS_TOKEN} ${newAccessToken}`;
              originalRequest.headers[AUTH_HEADER] =
                `${TOKEN_TYPE} ${newAccessToken}`;
              isRefreshing = false;
              refreshQueue.forEach((resolve: () => void) => resolve());
              refreshQueue = [];

              return request(originalRequest);
            }
          })
          .catch((resp) => {
            console.error(resp);
          });
      } else {
        return new Promise((resolve) => {
          refreshQueue.push(() => {
            originalRequest.headers[AUTH_HEADER] =
              `${TOKEN_TYPE} ${localStorage.getItem(ACCESS_TOKEN)}`;
            resolve(request(originalRequest));
          });
        });
      }
    } else {
      return Promise.reject(error);
    }
  },
);
refreshTokenIns.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response } = error;
    if (response?.status === 401) {
      localStorage.removeItem(ACCESS_TOKEN);
      window.location.href = "/login";
    }
  },
);

export default request;
