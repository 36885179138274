<script setup lang="ts">
import { DateTime } from "luxon";
import { computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useStore } from "@/store/main";
import { useFiltersStore } from "@/store/filters";
import { useAbortController } from "@/store/abortController";
import type { IBrandTypeWithGroupId, ChannelsType } from "@/types/main";

import CommonButton from "@/components/common/CommonButton.vue";
import CommonSkeleton from "@/components/common/CommonSkeleton.vue";
import BackComponent from "@/components/core/Filters/BackComponent.vue";

const route = useRoute();
const store = useStore();
const router = useRouter();
const filtersStore = useFiltersStore();
const abortController = useAbortController();

const brandsList = computed(
  () =>
    store.brands
      ?.filter((brand) =>
        filtersStore.brands.find(
          (brandShortName) => brandShortName === brand?.BRAND_SHORT_NAME,
        ),
      )
      .slice(0, 3) as IBrandTypeWithGroupId[],
);
const channelsList = computed(() =>
  store.channels
    ?.filter((channel) =>
      filtersStore.channels.find(
        (channelId) => channelId === channel?.CHANNEL_ID,
      ),
    )
    .slice(0, 1),
);

const removeBrand = (item: IBrandTypeWithGroupId) => {
  abortController.abortAllRequests();

  const brands = filtersStore.brands
    .filter((brand) => brand !== item.BRAND_SHORT_NAME)
    .map((brand) => brand);

  filtersStore.updatedBrandBySelect(true);
  filtersStore.updateBrandsInStore(brands);

  if (!brands.length) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { brands, ...rest } = route.query;
    router.push({ query: rest });
  }
};

const removeChannel = (item: ChannelsType) => {
  abortController.abortAllRequests();

  const channels: number[] = filtersStore.channels.filter(
    (channel) => channel !== item.CHANNEL_ID,
  ) as number[];

  filtersStore.updatedChannelBySelect(true);
  filtersStore.updateChannelsInStore(channels);

  if (!channels.length) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { channels, ...rest } = route.query;
    router.push({ query: rest });
  }
};

watch(
  () => route.meta.filters,
  () => {
    if (!route.meta.filters) filtersStore.updateFilterIsOpenSidebar(false);
  },
);
</script>

<template>
  <div class="w-full" v-if="route.meta.filters || route.meta.title">
    <div
      class="p-3 flex gap-[2rem] justify-end items-center"
      v-if="route.meta.filters"
    >
      <BackComponent />
      <div
        v-if="!filtersStore.loading"
        class="hidden md:flex items-center justify-end gap-5"
      >
        <section
          v-if="(route.meta.filters as string[])?.includes('brands')"
          class="flex flex-col text-[10px] text-mainText"
        >
          <article class="cursor-default">
            Brand{{ brandsList.length > 1 ? "s" : "" }}:
          </article>
          <article class="flex gap-2 justify-start items-center">
            <div
              v-for="brand in brandsList"
              :key="brand.BK_BRAND_ID"
              class="tip"
            >
              <span>
                {{ brand.BRAND_NAME }}
              </span>

              <span
                class="cursor-pointer flex justify-center items-center"
                @click="removeBrand(brand)"
              >
                <feather-icon type="x" size="10px" />
              </span>
            </div>
            <div v-if="filtersStore.brands.length > 3">
              +{{ filtersStore.brands.length - 3 }} More
            </div>
            <div v-if="!brandsList.length" class="tip">All Brands</div>
          </article>
        </section>
        <section
          v-if="(route.meta.filters as string[])?.includes('channels')"
          class="flex flex-col text-[10px] text-mainText"
        >
          <article class="cursor-default">
            Channels{{ channelsList.length > 1 ? "s" : "" }}:
          </article>
          <article class="flex gap-2 justify-start items-center">
            <div
              v-for="channel in channelsList"
              :key="channel.CHANNEL_ID"
              class="tip"
            >
              <span>
                {{ channel.NAME }}
              </span>
              <span
                class="cursor-pointer flex justify-center items-center"
                @click="removeChannel(channel)"
              >
                <feather-icon type="x" size="10px" />
              </span>
            </div>
            <div v-if="filtersStore.channels.length > 1">
              +{{ filtersStore.channels.length - 1 }} More
            </div>
            <div v-if="!channelsList.length" class="tip">All Channels</div>
          </article>
        </section>
        <section
          v-if="(route.meta.filters as string[])?.includes('picker')"
          class="flex flex-col justify-end h-full cursor-default"
        >
          <article class="text-[10px] flex gap-2 text-mainText">
            <span>Main Date Range</span>
            <span class="text-mainText font-medium">
              {{
                DateTime.fromJSDate(new Date(filtersStore.picker.since))
                  .toUTC()
                  .toFormat("MM/dd/yyyy")
              }}
              -
              {{
                DateTime.fromJSDate(new Date(filtersStore.picker.until))
                  .toUTC()
                  .toFormat("MM/dd/yyyy")
              }}
            </span>
          </article>
          <article class="text-[10px] flex gap-2 text-mainText">
            <span>Comparison Date Range</span>
            <span class="text-mainText font-medium">
              {{
                DateTime.fromJSDate(new Date(filtersStore.kpisPicker.since))
                  .toUTC()
                  .toFormat("MM/dd/yyyy")
              }}
              -
              {{
                DateTime.fromJSDate(new Date(filtersStore.kpisPicker.until))
                  .toUTC()
                  .toFormat("MM/dd/yyyy")
              }}
            </span>
          </article>
        </section>
      </div>
      <div v-else class="flex">
        <div class="flex gap-5">
          <div class="flex flex-col gap-2">
            <CommonSkeleton height="10px" width="70px" />
            <CommonSkeleton height="27px" width="60px" borderRadius="5px" />
          </div>
          <div class="flex flex-col gap-2">
            <CommonSkeleton height="10px" width="80px" />
            <CommonSkeleton height="27px" width="70px" borderRadius="5px" />
          </div>
          <div class="flex flex-col gap-2 justify-center">
            <div class="flex gap-2">
              <CommonSkeleton height="10px" width="80px" />
              <CommonSkeleton height="10px" width="130px" />
            </div>
            <div class="flex gap-2">
              <CommonSkeleton height="10px" width="110px" />
              <CommonSkeleton height="10px" width="130px" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="flex justify-end">
          <div>
            <CommonButton
              text="Filter Panel"
              @click="filtersStore.updateFilterIsOpenSidebar(true)"
              class="2xl:w-[160px]"
            >
              <template #iconLeft>
                <feather-icon type="filter" size="20px" />
              </template>
            </CommonButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
