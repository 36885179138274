<script setup lang="ts">
import { useRoute } from "vue-router";
import { ref, computed, nextTick } from "vue";
import { vOnClickOutside } from "@vueuse/components";

const route = useRoute();

const props = withDefaults(
  defineProps<{
    routeItem: any;
    depth?: number;
    smallMenu?: boolean;
    isOpen?: boolean;
  }>(),
  {
    depth: 0,
    smallMenu: false,
  },
);

const showChildren = ref(false);
const expanded = ref(false);
const containerHeight = ref<string | number>(0);
const containerRef: any = ref(null);

const close = () => {
  if (!props.smallMenu) return;
  showChildren.value = false;
  expanded.value = false;
};

const toggleMenu = () => {
  expanded.value = !expanded.value;
  if (!showChildren.value) {
    showChildren.value = true;
    nextTick(() => {
      containerHeight.value = containerRef.value.scrollHeight + "px";
      setTimeout(() => {
        containerHeight.value = "fit-content";
        if (navigator.userAgent.indexOf("Firefox") !== -1) {
          containerHeight.value = "-moz-max-content";
        }
        containerRef.value.style.overflow = "visible";
      }, 300);
    });
  } else {
    containerHeight.value = containerRef.value.scrollHeight + "px";
    containerRef.value.style.overflow = "hidden";
    setTimeout(() => {
      containerHeight.value = 0 + "px";
    }, 10);
    setTimeout(() => {
      showChildren.value = false;
    }, 300);
  }
};

const showLabel = computed(() => (props.smallMenu ? props.depth > 0 : true));
</script>

<template>
  <div
    class="menu-item"
    :class="{ opened: expanded }"
    v-on-click-outside="close"
  >
    <div
      class="label"
      :style="{
        paddingLeft: depth * 20 + 20 + 'px',
        'justify-content': !props.isOpen ? 'start' : 'center',
      }"
    >
      <div class="left">
        <router-link
          :to="{ path: props.routeItem.path, query: route.query }"
          class="flex gap-2 p-1 items-center text-[16px]"
          :class="{
            'text-primary': route.name === routeItem.name,
          }"
        >
          <feather-icon
            v-if="props.routeItem?.meta.icon"
            :type="props.routeItem?.meta.icon"
            :class="{ opened: expanded }"
            size="20"
          />

          <span v-if="showLabel">
            {{ props.routeItem?.meta.title }}
          </span>
        </router-link>
      </div>
      <div
        v-if="props.routeItem.children"
        class="right p-2"
        @click="toggleMenu"
      >
        <feather-icon
          type="chevron-down"
          class="expand-icon expand"
          :class="{ opened: expanded }"
        />
      </div>
    </div>
    <div
      v-show="showChildren"
      :class="{ 'small-menu': smallMenu }"
      class="items-container"
      :style="{ height: containerHeight }"
      ref="containerRef"
    >
      <MenuItem
        :class="{ opened: showChildren }"
        v-for="(item, index) in props.routeItem.children"
        :key="index"
        :routeItem="item"
        :depth="depth + 1"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-item {
  position: relative;
  width: 100%;
  .label {
    @apply text-secondaryText font-normal;
    width: 100%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    user-select: none;
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 14px;
    transition: all 0.3s ease;
    > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    i {
      font-size: 20px;
      transition: all 0.3s ease;
      &.expand {
        font-size: 16px;
        &.opened {
          transform: rotate(180deg);
        }
      }
    }
    &.small-item {
      width: fit-content;
    }
    &:hover {
      @apply hover:bg-hover cursor-pointer;
    }
  }
  .items-container {
    width: 100%;
    left: calc(100% + 6px);
    transition: height 0.3s ease;
    overflow: hidden;
    &.small-menu {
      width: fit-content;
      position: absolute;
      @apply bg-navigationBg;

      top: 0;
      .label {
        width: 100% !important;
        padding-left: 20px !important;
      }
    }
  }
}
</style>
