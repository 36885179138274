import { defineStore } from 'pinia';
import { DateTime } from 'luxon';

import type { TableRow } from '@/types/table';
import type { requestParams } from '@/types/requests';
import type { ReviewsStore } from '@/types/reviews/types';
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from '@/utils/createAbortControllers';

import {
  get5TopAndBottomProducts as get5TopAndBottomProductsApi,
  getProductReviews as getProductReviewsApi,
  getReviewsChart as getReviewsChartApi,
  getReviewsKpiDetails as getReviewsKpiDetailsApi,
  getReviewsKpiTotal as getReviewsKpiTotalApi,
  getReviewsRatings as getReviewsRatingsApi,
  getKpiRatingsOnCurrentOverallRatingsApi,
  getProductRatingTableApi,
  getCommentsTableApi,
} from '../../api/reviews';

const controllers: AbortController[] = [];
let tableController = new AbortController();
let tableRatingController = new AbortController();

export const useReviewsStore = defineStore('reviews', {
  state: (): ReviewsStore => ({
    filters: {
      granularity: 'MONTH',
    },
    KPITotal: null,
    KPIDetails: null,
    period: {
      since: DateTime.now()?.minus({ year: 1 }).toUTC().toISODate() as string,
      until: DateTime.now().toUTC().toISODate() as string,
    },
    reviewRating: null,
    isKpiLoader: true,
    WoWKpiData: null,
    MoMKpiData: null,
    YoYKpiData: null,
    PoPKpiData: null,
    reviewsChart: [],
    isChartLoader: false,
    Top5Products: [],
    Bottom5Products: [],
    isTopProductsLoader: true,
    isRatingLoader: true,
    isProductReviewsLoading: true,
    isProductReviewsTotalLoading: true,
    reviewProductsTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    periodValues: [],
    KpiRatingsOnCurrentOverallRatings: null,
    isKpiRatingsOnCurrentOverallRatingsLoader: true,
    productRatingTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    commentsTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    isCommentsTableLoader: true,
    isCommentsTableTotalLoader: true,
    isProductRatingTableLoader: true,
    isProductRatingTableTotalLoader: true,
  }),

  actions: {
    abortRequestsReviewStore() {
      this.clearStore();
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortTableRequest() {
      tableController = replaceSingleController(tableController);
    },

    abortTableRatingRequest() {
      tableRatingController = replaceSingleController(tableRatingController);
    },

    async getKpiRatingsOnCurrentOverallRatings(params: requestParams) {
      this.isKpiRatingsOnCurrentOverallRatingsLoader = true;
      try {
        const { data } = await getKpiRatingsOnCurrentOverallRatingsApi(params, {
          signal: getAbortSignal(controllers),
        });
        this.KpiRatingsOnCurrentOverallRatings = data;

        this.isKpiRatingsOnCurrentOverallRatingsLoader = false;
      } catch (error) {
        this.isKpiRatingsOnCurrentOverallRatingsLoader = false;
        /* empty */
      }
    },

    async getProductRatingTable(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isProductRatingTableTotalLoader = true;
      } else {
        this.isProductRatingTableLoader = true;
      }
      try {
        const { data } = await getProductRatingTableApi(params, {
          signal: tableRatingController.signal,
        });

        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.productRatingTable.data = data.data;
          this.productRatingTable.pagination = data.pagination;
        }
        this.isProductRatingTableLoader = false;
        this.isProductRatingTableTotalLoader = false;
      } catch (err: any) {
        const errorData = JSON.parse(err?.message);
        this.isProductRatingTableLoader = false;
        this.isProductRatingTableTotalLoader = false;
        if (errorData.status === 'canceled') {
          this.isProductRatingTableLoader = true;
        }
      }
    },

    async getCommentsTable(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isCommentsTableTotalLoader = true;
      } else {
        this.isCommentsTableLoader = true;
      }
      try {
        const { data } = await getCommentsTableApi(params, {
          signal: tableController.signal,
        });

        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.commentsTable.data = data.data;
          this.commentsTable.pagination = data.pagination;
        }
        this.isCommentsTableLoader = false;
        this.isCommentsTableTotalLoader = false;
      } catch (err: any) {
        const errorData = JSON.parse(err?.message);
        this.isCommentsTableLoader = false;
        this.isCommentsTableTotalLoader = false;
        if (errorData.status === 'canceled') {
          this.isCommentsTableLoader = true;
        }
      }
    },

    async getReviewsKpiTotal(params: requestParams) {
      try {
        const res = await getReviewsKpiTotalApi(params, {
          signal: getAbortSignal(controllers),
        });
        if (res.status >= 200 && res.status < 400) {
          this.KPITotal = res.data;
        } else {
          return null;
        }
      } catch (err) {
        /* empty */
      }
    },

    async getKpiDetails(
      params: requestParams,
      type?: 'WoWKpiData' | 'MoMKpiData' | 'YoYKpiData' | 'PoPKpiData',
    ) {
      return getReviewsKpiDetailsApi(params, {
        signal: getAbortSignal(controllers),
      })
        .then(({ data }) => {
          if (type) {
            this[type] = null;
            this[type] = data;
          } else {
            return (this.KPIDetails = data);
          }
        })
        .catch((e) => {})
        .finally(() => {});
    },

    updateKpiLoader(value: boolean) {
      this.isKpiLoader = value;
    },

    fetchReviewsRatings(params: requestParams) {
      this.isRatingLoader = true;
      getReviewsRatingsApi(params, {
        signal: getAbortSignal(controllers),
      })
        .then(({ data }) => {
          this.reviewRating = data;
        })
        .catch((e) => {})
        .finally(() => {
          this.isRatingLoader = false;
        });
    },

    updateGranularity(payload: 'MONTH' | 'WEEK') {
      this.filters.granularity = payload;
    },

    getChartReviews(params: requestParams) {
      this.isChartLoader = true;
      getReviewsChartApi(params, {
        signal: getAbortSignal(controllers),
      })
        .then(({ data }) => {
          this.reviewsChart = data;
        })
        .catch((e) => {})
        .finally(() => {
          this.isChartLoader = false;
        });
    },

    get5TopAndBottomProducts(params: requestParams) {
      this.isTopProductsLoader = true;
      get5TopAndBottomProductsApi(params, {
        signal: getAbortSignal(controllers),
      })
        .then(({ data }) => {
          this.Top5Products = data?.TOP5;
          this.Bottom5Products = data?.BOTTOM5;
        })
        .catch((e) => {})
        .finally(() => {
          this.isTopProductsLoader = false;
        });
    },

    getProductReviews(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isProductReviewsTotalLoading = true;
      } else {
        this.isProductReviewsLoading = true;
      }
      getProductReviewsApi(params, {
        signal: tableController.signal,
      })
        .then(({ data }) => {
          if (params.limit === 0) {
            callback && callback(data.data);
          } else {
            this.reviewProductsTable.data = data.data;
            this.reviewProductsTable.pagination = data.pagination;
          }
          this.isProductReviewsLoading = false;
          this.isProductReviewsTotalLoading = false;
        })
        .catch((err: any) => {
          const errorData = JSON.parse(err?.message);
          this.isProductReviewsLoading = false;
          this.isProductReviewsTotalLoading = false;
          if (errorData.status === 'canceled') {
            this.isProductReviewsLoading = true;
          }
        });
    },

    clearStore() {
      this.KPITotal = null;
      this.KPIDetails = null;
      this.reviewRating = null;
      this.WoWKpiData = null;
      this.MoMKpiData = null;
      this.YoYKpiData = null;
      this.PoPKpiData = null;
      this.reviewsChart = [];
      this.Top5Products = [];
      this.Bottom5Products = [];
      this.reviewProductsTable = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
      this.isKpiLoader = true;
      this.isChartLoader = false;
      this.isTopProductsLoader = true;
      this.isRatingLoader = true;
      this.isProductReviewsLoading = true;
      this.isProductReviewsTotalLoading = true;
    },
  },

  getters: {
    totalKpiValue() {
      const values: {
        name: string;
        value: { difference: number; ratio_percentage: number } | undefined;
      }[] = [
        { name: 'PoP', value: this.PoPKpiData?.TotalReview },
        { name: 'WoW', value: this.WoWKpiData?.TotalReview },
        { name: 'MoM', value: this.MoMKpiData?.TotalReview },
        { name: 'YoY', value: this.YoYKpiData?.TotalReview },
      ];

      return {
        name: 'Total Reviews',
        values: values,
      };
    },
    positiveKpiValue() {
      const values: {
        name: string;
        value: { difference: number; ratio_percentage: number } | undefined;
      }[] = [
        { name: 'PoP', value: this.PoPKpiData?.PositiveReviews },
        { name: 'WoW', value: this.WoWKpiData?.PositiveReviews },
        { name: 'MoM', value: this.MoMKpiData?.PositiveReviews },
        { name: 'YoY', value: this.YoYKpiData?.PositiveReviews },
      ];

      return {
        name: 'Positive Reviews',
        values: values,
      };
    },
    negativeKpiValue() {
      const values: {
        name: string;
        value: { difference: number; ratio_percentage: number } | undefined;
      }[] = [
        { name: 'PoP', value: this.PoPKpiData?.NegativeReviews },
        { name: 'WoW', value: this.WoWKpiData?.NegativeReviews },
        { name: 'MoM', value: this.MoMKpiData?.NegativeReviews },
        { name: 'YoY', value: this.YoYKpiData?.NegativeReviews },
      ];

      return {
        name: 'Negative Reviews',
        values: values,
      };
    },

    avgKpiValue() {
      const values: {
        name: string;
        value: { difference: number; ratio_percentage: number } | undefined;
      }[] = [
        { name: 'PoP', value: this.PoPKpiData?.AverageRating },
        { name: 'WoW', value: this.WoWKpiData?.AverageRating },
        { name: 'MoM', value: this.MoMKpiData?.AverageRating },
        { name: 'YoY', value: this.YoYKpiData?.AverageRating },
      ];

      return {
        name: 'Average Rating',
        values: values,
      };
    },
  },
});
