<script setup lang="ts">
import { useColorMode } from "@vueuse/core";

import MasterLayout from "@/layouts/MasterLayout.vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { system, store } = useColorMode();
</script>

<template>
  <MasterLayout />
</template>
