import axios from "@/utils/axios";

import type { requestParams, PaginatedWrapper } from "@/types/requests";
import type {
  KpiDetails,
  KpiTotal,
  Product5TopAndBottom,
  ReviewProductsTableData,
  ReviewRating,
  ReviewsChart,
  IKpiRatingsOnCurrentOverallRatings,
  IProductRatingTable,
  ICommentsTable,
} from "@/types/reviews/types";

export const getReviewsKpiTotal = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<KpiTotal>("/reviews/kpi/totals", params, options);
};

export const getReviewsKpiDetails = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<KpiDetails>("/reviews/kpi/details", params, options);
};

export const getReviewsRatings = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ReviewRating>("/reviews/ratings", params, options);
};

export const getReviewsChart = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ReviewsChart[]>("/reviews/ratings-graph", params, options);
};

export const get5TopAndBottomProducts = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<Product5TopAndBottom>(
    "/reviews/topAndBottom5Products",
    params,
    options,
  );
};

export const getProductReviews = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<ReviewProductsTableData[]>>(
    "/reviews/product-reviews",
    params,
    options,
  );
};

export const getKpiRatingsOnCurrentOverallRatingsApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<IKpiRatingsOnCurrentOverallRatings>(
    "/reviews/kpi-ratings",
    params,
    options,
  );
};

export const getProductRatingTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<IProductRatingTable[]>>(
    "/reviews/product-ratings-table",
    params,
    options,
  );
};

export const getCommentsTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<ICommentsTable[]>>(
    "/reviews/reviews-with-comments-table",
    params,
    options,
  );
};
