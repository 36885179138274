<script setup lang="ts"></script>

<template>
  <section>
    <header></header>
    <main>
      <router-view />
    </main>
  </section>
</template>
