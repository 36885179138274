import { defineStore } from 'pinia';

import type { TableRow } from '@/types/table';
import type { requestParams } from '@/types/requests';
import {
  getMSRPorCOGSTableApi,
  getNotInNetsuiteTableApi,
  getTransparencyEnabledTableApi,
  getNoShelfLifeTableApi,
  getNoFNSKUTableApi,
  getNoPrepDescriptionsTableApi,
  getAmazonFBAInactiveTableApi,
  getMissingDimsOrWeghtTableApi,
} from '@/api/productAlert';
import { replaceSingleController } from '@/utils/createAbortControllers';
import type { ProductAlertStore } from '@/types/productAlerts';

let noShelfLifeTableController = new AbortController();
let noMSRPorCOGSTableController = new AbortController();
let notInNetsuiteTableController = new AbortController();
let transparencyEnabledTableController = new AbortController();
let noFNSKUTableController = new AbortController();
let noPrepDescriptionsTableController = new AbortController();
let amazonFBATableController = new AbortController();
let missingDIMSTableController = new AbortController();

export const useProductAlertsStore = defineStore('productAlertsStore', {
  state: (): ProductAlertStore => ({
    noMSRPorCOGSKpi: null,
    notInNetsuiteKpi: null,
    transparencyEnabledKpi: null,
    noFNSKUKpi: null,
    noShelfLifeKpi: null,
    noPrepDescripionsKpi: null,
    amazonFBAKpi: null,
    missingDIMSKpi: null,
    noMSRPorCOGSTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    notInNetsuiteTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    transparencyEnabledTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    noShelfLifeTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    noFNSKUTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    noPrepDescriptionsTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    amazonFBATable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    missingDIMSTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    isNoMSRPorCOGSTableLoader: true,
    isNoMSRPorCOGSTotalTableLoader: true,
    isNotInNetsuiteTableLoader: true,
    isNotInNetsuiteTotalTableLoader: true,
    isTransparencyEnabledTableLoader: true,
    isTransparencyEnabledTotalTableLoader: true,
    isNoShelfLifeTableLoader: true,
    isNoShelfLifeTotalTableLoader: true,
    isNoFNSKUTableLoader: true,
    isNoFNSKUTotalTableLoader: true,
    isNoPrepDescriptionsTableLoader: true,
    isNoPrepDescriptionsTotalTableLoader: true,
    isAmazonFBATableLoader: true,
    isAmazonFBATotalTableLoader: true,
    isMissingDIMSTableLoader: true,
    isMissingDIMSTotalTableLoader: true,
  }),

  actions: {
    abortNoMSRPorCOGSTable() {
      noMSRPorCOGSTableController = replaceSingleController(noMSRPorCOGSTableController);
    },

    abortNotInNetsuiteTable() {
      notInNetsuiteTableController = replaceSingleController(notInNetsuiteTableController);
    },

    abortTransparencyEnabledTable() {
      transparencyEnabledTableController = replaceSingleController(
        transparencyEnabledTableController,
      );
    },

    abortNoShelfLifeTable() {
      noShelfLifeTableController = replaceSingleController(noShelfLifeTableController);
    },

    abortNoFNSKUTable() {
      noFNSKUTableController = replaceSingleController(noFNSKUTableController);
    },

    abortNoPrepDescriptionsTable() {
      noPrepDescriptionsTableController = replaceSingleController(
        noPrepDescriptionsTableController,
      );
    },

    abortAmazonFBATable() {
      amazonFBATableController = replaceSingleController(amazonFBATableController);
    },

    abortMissingDIMSTable() {
      missingDIMSTableController = replaceSingleController(missingDIMSTableController);
    },

    async getNoMSRPorCOGSTable(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isNoMSRPorCOGSTotalTableLoader = true;
      } else {
        this.isNoMSRPorCOGSTableLoader = true;
      }
      try {
        const { data } = await getMSRPorCOGSTableApi(params, {
          signal: noMSRPorCOGSTableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.noMSRPorCOGSTable.data = data.data;
          this.noMSRPorCOGSTable.pagination = data.pagination;
          this.noMSRPorCOGSKpi = data.pagination.totalItems as number;
        }
        this.isNoMSRPorCOGSTableLoader = false;
        this.isNoMSRPorCOGSTotalTableLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isNoMSRPorCOGSTotalTableLoader = false;
        this.isNoMSRPorCOGSTotalTableLoader = false;
        if (errorData.status === 'canceled') {
          this.isNoMSRPorCOGSTableLoader = true;
        }
      }
    },

    async getNotInNetsuiteTable(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isNotInNetsuiteTotalTableLoader = true;
      } else {
        this.isNotInNetsuiteTableLoader = true;
      }
      try {
        const { data } = await getNotInNetsuiteTableApi(params, {
          signal: notInNetsuiteTableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.notInNetsuiteTable.data = data.data;
          this.notInNetsuiteTable.pagination = data.pagination;
          this.notInNetsuiteKpi = data.pagination.totalItems as number;
        }
        this.isNotInNetsuiteTableLoader = false;
        this.isNotInNetsuiteTotalTableLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isNotInNetsuiteTableLoader = false;
        this.isNotInNetsuiteTotalTableLoader = false;
        if (errorData.status === 'canceled') {
          this.isNotInNetsuiteTableLoader = true;
        }
      }
    },

    async getTransparencyEnabledTable(
      params: requestParams,
      callback?: (data: TableRow[]) => void,
    ) {
      if (params.limit === 0) {
        this.isTransparencyEnabledTotalTableLoader = true;
      } else {
        this.isTransparencyEnabledTableLoader = true;
      }
      try {
        const { data } = await getTransparencyEnabledTableApi(params, {
          signal: transparencyEnabledTableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.transparencyEnabledTable.data = data.data;
          this.transparencyEnabledTable.pagination = data.pagination;
          this.transparencyEnabledKpi = data.pagination.totalItems as number;
        }
        this.isTransparencyEnabledTableLoader = false;
        this.isTransparencyEnabledTotalTableLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isTransparencyEnabledTableLoader = false;
        this.isTransparencyEnabledTotalTableLoader = false;
        if (errorData.status === 'canceled') {
          this.isTransparencyEnabledTableLoader = true;
        }
      }
    },

    async getNoShelfLifeTable(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isNoShelfLifeTotalTableLoader = true;
      } else {
        this.isNoShelfLifeTableLoader = true;
      }
      try {
        const { data } = await getNoShelfLifeTableApi(params, {
          signal: noShelfLifeTableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.noShelfLifeTable.data = data.data;
          this.noShelfLifeTable.pagination = data.pagination;
          this.noShelfLifeKpi = data.pagination.totalItems as number;
        }
        this.isNoShelfLifeTableLoader = false;
        this.isNoShelfLifeTotalTableLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isNoShelfLifeTableLoader = false;
        this.isNoShelfLifeTotalTableLoader = false;
        if (errorData.status === 'canceled') {
          this.isNoShelfLifeTableLoader = true;
        }
      }
    },

    async getNoFNSKUTable(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isNoFNSKUTotalTableLoader = true;
      } else {
        this.isNoFNSKUTableLoader = true;
      }
      try {
        const { data } = await getNoFNSKUTableApi(params, {
          signal: noFNSKUTableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.noFNSKUTable.data = data.data;
          this.noFNSKUTable.pagination = data.pagination;
          this.noFNSKUKpi = data.pagination.totalItems as number;
        }
        this.isNoFNSKUTableLoader = false;
        this.isNoFNSKUTotalTableLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isNoFNSKUTableLoader = false;
        this.isNoFNSKUTotalTableLoader = false;
        if (errorData.status === 'canceled') {
          this.isNoFNSKUTableLoader = true;
        }
      }
    },

    async getNoPrepDescriptionsTable(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isNoPrepDescriptionsTotalTableLoader = true;
      } else {
        this.isNoPrepDescriptionsTableLoader = true;
      }
      try {
        const { data } = await getNoPrepDescriptionsTableApi(params, {
          signal: noPrepDescriptionsTableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.noPrepDescriptionsTable.data = data.data;
          this.noPrepDescriptionsTable.pagination = data.pagination;
          this.noPrepDescripionsKpi = data.pagination.totalItems as number;
        }
        this.isNoPrepDescriptionsTableLoader = false;
        this.isNoPrepDescriptionsTotalTableLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isNoPrepDescriptionsTableLoader = false;
        this.isNoPrepDescriptionsTotalTableLoader = false;
        if (errorData.status === 'canceled') {
          this.isNoPrepDescriptionsTableLoader = true;
        }
      }
    },

    async getAmazonFBATable(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isAmazonFBATotalTableLoader = true;
      } else {
        this.isAmazonFBATableLoader = true;
      }
      try {
        const { data } = await getAmazonFBAInactiveTableApi(params, {
          signal: amazonFBATableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.amazonFBATable.data = data.data;
          this.amazonFBATable.pagination = data.pagination;
          this.amazonFBAKpi = data.pagination.totalItems as number;
        }
        this.isAmazonFBATableLoader = false;
        this.isAmazonFBATotalTableLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isAmazonFBATableLoader = false;
        this.isAmazonFBATotalTableLoader = false;
        if (errorData.status === 'canceled') {
          this.isAmazonFBATableLoader = true;
        }
      }
    },

    async getMissingDIMSTable(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isMissingDIMSTotalTableLoader = true;
      } else {
        this.isMissingDIMSTableLoader = true;
      }
      try {
        const { data } = await getMissingDimsOrWeghtTableApi(params, {
          signal: missingDIMSTableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.missingDIMSTable.data = data.data;
          this.missingDIMSTable.pagination = data.pagination;
          this.missingDIMSKpi = data.pagination.totalItems as number;
        }
        this.isMissingDIMSTableLoader = false;
        this.isMissingDIMSTotalTableLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isMissingDIMSTableLoader = false;
        this.isMissingDIMSTotalTableLoader = false;
        if (errorData.status === 'canceled') {
          this.isMissingDIMSTableLoader = true;
        }
      }
    },

    // cleanStore() {
    //   this.inventoryKpis = null;
    //   this.tableData = {
    //     pagination: {
    //       totalPages: 1,
    //       currentPage: 1,
    //       pageSizeNumber: 10,
    //     },
    //     data: [],
    //   };
    // },
  },

  getters: {},
});
