import axios from "@/utils/axios";

import type { requestParams, PaginatedWrapper } from "@/types/requests";
import type { InventoryKpiType, InventoryTableType } from "@/types/inventory";

export const getInventoryKpiApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<InventoryKpiType>("/inventory/kpi", params, options);
};

export const getInventoryTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<InventoryTableType[]>>(
    "/inventory/table",
    params,
    options,
  );
};
