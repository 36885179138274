import { DateTime, Interval } from 'luxon';

export const parseDateUtils = (
  start: string,
  end: string,
  currentGranylarityItem: 'MONTH' | 'WEEK',
  formattedDatesArray: { value: string[] },
) => {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);

  const interval = Interval.fromDateTimes(startDate, endDate);

  if (currentGranylarityItem === 'MONTH') {
    formattedDatesArray.value = interval
      .splitBy({ months: 1 })
      .map((dt: any) => dt.start.startOf('month').toFormat('LLL yyyy'));

    const lastMonth = DateTime.fromISO(end).startOf('month');
    if (!formattedDatesArray.value.includes(lastMonth.toFormat('LLL yyyy'))) {
      formattedDatesArray.value.push(lastMonth.toFormat('LLL yyyy'));
    }

    formattedDatesArray.value.sort(
      (a: string, b: string) =>
        DateTime.fromFormat(a, 'LLL yyyy').toMillis() -
        DateTime.fromFormat(b, 'LLL yyyy').toMillis(),
    );
  } else if (currentGranylarityItem === 'WEEK') {
    formattedDatesArray.value = [];
    let cursor = interval.start?.startOf('day');
    if (cursor && cursor?.weekday !== 7) {
      cursor = cursor!.plus({ days: 7 - cursor.weekday });
    }
    while (interval.contains(cursor as any)) {
      formattedDatesArray.value.push(cursor!.toFormat('MM/dd/yyyy'));
      cursor = cursor!.plus({ weeks: 1 });
    }
  }

  return formattedDatesArray.value;
};

export const changingMonthToWeekListForTable = (
  currentDate: [string, string],
  periodValues: string[],
  granularity: 'MONTH' | 'WEEK',
  summary?: boolean,
): { name: string; value: string }[] => {
  let uniqueDates = [];

  const selectOptions: (Record<string, string | null> | undefined)[] = [];
  if (currentDate) {
    const defaultObject: Record<string, string>[] = summary
      ? [
          {
            name: granularity === 'MONTH' ? 'All Months' : 'All Weeks',
            value: 'all',
          },
          {
            name: 'Summary',
            value: 'summary',
          },
        ]
      : [
          {
            name: granularity === 'MONTH' ? 'All Months' : 'All Weeks',
            value: 'all',
          },
        ];
    selectOptions.unshift(...defaultObject);
    uniqueDates = [...periodValues];

    const options = uniqueDates
      .map((date) => {
        if (granularity === 'MONTH') {
          return {
            name: DateTime.fromFormat(date, 'LLL yyyy').toFormat('MMMM yyyy'),
            value: DateTime.fromFormat(date, 'LLL yyyy').startOf('month').toISODate(),
          };
        } else {
          const monthStart = DateTime.fromFormat(date, 'LLL yyyy').startOf('month');
          const monthEnd = DateTime.fromFormat(date, 'LLL yyyy').endOf('month');

          let currentWeekStart = monthStart.startOf('week');
          const weekStartDates: string[] = [];

          if (currentWeekStart.weekday !== 7) {
            currentWeekStart = currentWeekStart.minus({ days: currentWeekStart.weekday });
          }

          while (currentWeekStart < monthEnd) {
            weekStartDates.push(currentWeekStart.toISODate() as string);
            currentWeekStart = currentWeekStart.plus({ weeks: 1 });
          }
          if (weekStartDates.length > 1 && monthEnd.weekday !== 7) {
            weekStartDates.pop();
          }

          weekStartDates.forEach((weekStartDate) => {
            const startOfWeek = DateTime.fromISO(weekStartDate);
            if (startOfWeek >= DateTime.local().startOf('day')) {
              return;
            }
            const option = {
              name: DateTime.fromISO(weekStartDate).toFormat('MM/dd/yyyy'),
              value: DateTime.fromISO(weekStartDate).toISODate(),
            };

            const optionSet = new Set(selectOptions.map((opt) => opt?.value));
            if (!optionSet.has(option.value)) {
              selectOptions.push(option);
            }
          });
        }
      })
      .flat();

    selectOptions.push(...options);
  }

  const cleanedSelectOptions = selectOptions
    .filter((opt) => opt !== null && opt !== undefined)
    .sort(
      (
        a: Record<string, string | null> | undefined,
        b: Record<string, string | null> | undefined,
      ) => {
        return new Date(b?.value as string).getTime() - new Date(a?.value as string).getTime();
      },
    );

  return cleanedSelectOptions as { name: string; value: string }[];
};

export const formattigDateForRequestParams = (
  selectOption: string,
  granularity: 'MONTH' | 'WEEK',
) => {
  if (selectOption === 'summary') return {};

  if (selectOption === 'last30days') {
    return {
      period: {
        since: DateTime.now().minus({ days: 30 }),
        until: DateTime.now(),
      },
    };
  }

  if (selectOption === 'last60days') {
    return {
      period: {
        since: DateTime.now().minus({ days: 60 }),
        until: DateTime.now(),
      },
    };
  }
  if (selectOption === 'last90days') {
    return {
      period: {
        since: DateTime.now().minus({ days: 90 }),
        until: DateTime.now(),
      },
    };
  }
  if (selectOption === 'lastyear') {
    return {
      period: {
        since: DateTime.now().minus({ days: 365 }),
        until: DateTime.now(),
      },
    };
  }
  if (selectOption !== 'all') {
    if (granularity === 'MONTH') {
      const initialDate = DateTime.fromISO(selectOption);
      const oneMonthLater = initialDate.endOf('month');
      DateTime.fromFormat(selectOption, 'yyyy-MM-dd').toFormat('LLL yyyy');
      return {
        period: {
          since: selectOption,
          until: oneMonthLater.toISODate(),
        },
      };
    } else {
      return {
        period: {
          since: DateTime.fromFormat(selectOption, 'yyyy-MM-dd'),
          until: DateTime.fromFormat(selectOption, 'yyyy-MM-dd').plus({ days: 6 }),
        },
      };
    }
  } else return {};
};
