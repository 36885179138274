<script setup lang="ts">
import { watch } from "vue";
import { debounce } from "lodash";

type Props = {
  text?: string;
  modelValue?: string;
  placeholder?: string;
  debounceProp?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  text: "Search",
});

const emit = defineEmits<{
  (event: "update:modelValue", value: string): void;
  (event: "searchClick"): void;
  (event: "requestData", value: string): void;
  (event: "itemClick", value: any): void;
}>();

const debouncedEmitUpdate = debounce((value) => {
  emit("update:modelValue", value);
}, 300);

const handleInput = (e: Event) => {
  const inputValue = (e?.target as HTMLInputElement).value;
  if (props.debounceProp) {
    debouncedEmitUpdate(inputValue);
  } else {
    emit("update:modelValue", (e?.target as HTMLInputElement).value);
  }
};

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue === "") {
      emit("update:modelValue", "");
    }
  },
);
</script>

<template>
  <div class="relative">
    <span class="absolute left-[1rem] top-[10px]">
      <feather-icon type="search" size="20" class="text-secondaryText" />
    </span>

    <input
      type="text"
      class="flex rounded-lg items-center text-light border-none input-style bg-inputBg shadow-md dark:shadow-slate-900/50"
      :placeholder="props.placeholder ?? 'Search by Name, ASIN or SKU'"
      @input="handleInput"
      :value="props.modelValue"
      v-bind="$attrs"
    />
  </div>
</template>

<style scoped lang="scss">
.input-style {
  padding: 20.5px 0 20.5px 40px;
  font-style: normal;
  line-height: 19px; /* 118.75% */
  @apply text-[0.8rem] min-w-[250px] text-secondaryText h-[40px];
}
</style>
