<script setup lang="ts">
import { useRouter } from "vue-router";

import { AuthUserType, useAuthStore } from "@/store/auth/index";

import ThemeSwitcher from "@/components/common/ThemeSwitcher.vue";
import { useToast } from "@/composables/useToast";

const router = useRouter();
const { danger } = useToast();
const authStore = useAuthStore();
const { logout } = useAuthStore();

const props = defineProps<{
  user: AuthUserType;
}>();

const logoutUser = async function () {
  await logout();
  if (authStore.token.length === 0) {
    await router.push("/login");
    // success('Successfully signed up');
  } else {
    danger("Something got wrong. Try again");
  }
};
</script>

<template>
  <section class="flex flex-col gap-3">
    <span class="text-mainText font-medium">My Profile</span>
    <div class="flex flex-col gap-3">
      <article class="flex gap-3">
        <span>
          {{ props.user?.name }}
        </span>
        <span class="text-secondaryText font-extralight capitalize">
          {{ props.user?.role }}
        </span>
      </article>
      <div class="flex items-center gap-2">
        <feather-icon type="mail" size="15" class="text-secondaryText" />
        <span class="text-secondaryText text-[12px] font-light underl">
          {{ props.user?.email }}
        </span>
      </div>
    </div>
    <router-link
      to="/password-change"
      class="text-primary font-extralight cursor-pointer hover:text-primary w-fit underline transition-all duration-500"
      >Change password</router-link
    >

    <ThemeSwitcher class="styleForSwitch" />
    <div class="flex justify-end">
      <span
        class="cursor-pointer text-red-600 font-bold hover:text-red-400 transition-all duration-500 flex items-center gap-2"
        @click="logoutUser"
        ><feather-icon type="log-out" size="20" />Logout
      </span>
    </div>
  </section>
</template>
