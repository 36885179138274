import { defineStore } from "pinia";

import type { TableRow } from "@/types/table";
import type { requestParams } from "@/types/requests";
import type { ProductReturnsStore } from "@/types/productReturns";
import type { KPIGetterWrapper, KPIValue } from "@/types/Kpi";
import {
  getProductReturnsChart as getProductReturnsChartApi,
  getProductReturnsKPI as getProductReturnsKPIApi,
  getProductReturnsKPITotals as getProductReturnsKPITotalsApi,
  getProductReturnsTable as getProductReturnsTableApi,
  getTopReasonsTableApi,
  getReasonsTableApi,
} from "@/api/productReturns";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers";

const controllers: AbortController[] = [];
let tableController = new AbortController();
let reasonTableController = new AbortController();

export const useProductReturnsStore = defineStore("product-returns", {
  state: (): ProductReturnsStore => ({
    filters: {
      granularity: "MONTH",
    },
    periodValues: [],
    returnsChart: [],
    returnsKpiTotal: null,
    returnsTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    reasonsTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    topReasons: [],
    WoWKpiData: null,
    MoMKpiData: null,
    YoYKpiData: null,
    PoPKpiData: null,
    isProductReturnsKpiLoader: true,
    isProductReturnsChartLoader: true,
    isProductReturnsTableLoader: true,
    isProductReturnsKpiTotalsLoader: true,
    isTopReasonsLoader: true,
    isReasonsLoader: true,
    isReasonsTotalLoader: true,
  }),
  actions: {
    abortRequestsProductReturnsStore() {
      this.cleanStore();
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortTableRequest() {
      tableController = replaceSingleController(tableController);
    },

    abortReasonTableRequest() {
      reasonTableController = replaceSingleController(reasonTableController);
    },

    async fetchReturnsChart(params: requestParams) {
      this.isProductReturnsChartLoader = true;
      try {
        const { data } = await getProductReturnsChartApi(params, {
          signal: getAbortSignal(controllers),
        });
        this.returnsChart = data;
      } catch (err) {
        /* empty */
      } finally {
        this.isProductReturnsChartLoader = false;
      }
    },

    async fetchReturnsKPI(
      params: requestParams,
      type: "WoWKpiData" | "MoMKpiData" | "YoYKpiData" | "PoPKpiData",
    ) {
      try {
        const { data } = await getProductReturnsKPIApi(params, {
          signal: getAbortSignal(controllers),
        });

        this[type] = null;
        this[type] = data;
      } catch (err) {
        /* empty */
      }
    },

    updateKPILoader(value: boolean) {
      this.isProductReturnsKpiLoader = value;
    },

    async fetchReturnsKPITotals(params: requestParams) {
      try {
        const { data } = await getProductReturnsKPITotalsApi(params, {
          signal: getAbortSignal(controllers),
        });
        this.returnsKpiTotal = data;
      } catch (error) {
        /* empty */
      }
    },

    async fetchReturnsTable(
      params: requestParams,
      callback?: (data: TableRow[]) => void,
    ) {
      if (params.limit === 0) {
        this.isProductReturnsKpiTotalsLoader = true;
      } else {
        this.isProductReturnsTableLoader = true;
      }
      try {
        const { data } = await getProductReturnsTableApi(params, {
          signal: tableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.returnsTable.data = data.data;
          this.returnsTable.pagination = data.pagination;
        }
        this.isProductReturnsTableLoader = false;
        this.isProductReturnsKpiTotalsLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isProductReturnsTableLoader = false;
        this.isProductReturnsKpiTotalsLoader = false;
        if (errorData.status === "canceled") {
          this.isProductReturnsTableLoader = true;
        }
      }
    },

    async fetchTopReasonsTable(params: requestParams) {
      this.isTopReasonsLoader = true;
      try {
        const { data } = await getTopReasonsTableApi(params, {
          signal: getAbortSignal(controllers),
        });
        this.topReasons = data;
      } catch (error) {
        /* empty */
      } finally {
        this.isTopReasonsLoader = false;
      }
    },

    async fetchReasonsTable(
      params: requestParams,
      callback?: (data: TableRow[]) => void,
    ) {
      if (params.limit === 0) {
        this.isReasonsTotalLoader = true;
      } else {
        this.isReasonsLoader = true;
      }
      try {
        const { data } = await getReasonsTableApi(params, {
          signal: reasonTableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.reasonsTable.data = data.data;
          this.reasonsTable.pagination = data.pagination;
        }
        this.isReasonsLoader = false;
        this.isReasonsTotalLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isReasonsLoader = false;
        this.isReasonsTotalLoader = false;
        if (errorData.status === "canceled") {
          this.isReasonsLoader = true;
        }
      }
    },

    cleanStore() {
      this.returnsChart = [];
      this.returnsKpiTotal = null;
      this.returnsTable = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
      this.WoWKpiData = null;
      this.MoMKpiData = null;
      this.YoYKpiData = null;
      this.PoPKpiData = null;
      this.isProductReturnsKpiLoader = true;
      this.isProductReturnsChartLoader = true;
      this.isProductReturnsTableLoader = true;
      this.isProductReturnsKpiTotalsLoader = true;
    },
  },
  getters: {
    returnRate(): KPIGetterWrapper {
      const values: KPIValue[] = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.RETURN_RATE_DIFFERENCE || "",
            ratio_percentage:
              this.PoPKpiData?.RETURN_RATE_RATIO_PERCENTAGE || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.RETURN_RATE_DIFFERENCE || "",
            ratio_percentage:
              this.WoWKpiData?.RETURN_RATE_RATIO_PERCENTAGE || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.RETURN_RATE_DIFFERENCE || "",
            ratio_percentage:
              this.MoMKpiData?.RETURN_RATE_RATIO_PERCENTAGE || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.RETURN_RATE_DIFFERENCE || "",
            ratio_percentage:
              this.YoYKpiData?.RETURN_RATE_RATIO_PERCENTAGE || "",
          },
        },
      ];

      return {
        name: "ReturnRate",
        values: values,
      };
    },
    totalOrders(): KPIGetterWrapper {
      const values: KPIValue[] = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.PRODUCT_SALES_DIFFERENCE || "",
            ratio_percentage:
              this.PoPKpiData?.PRODUCT_SALES_RATIO_PERCENTAGE || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.PRODUCT_SALES_DIFFERENCE || "",
            ratio_percentage:
              this.WoWKpiData?.PRODUCT_SALES_RATIO_PERCENTAGE || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.PRODUCT_SALES_DIFFERENCE || "",
            ratio_percentage:
              this.MoMKpiData?.PRODUCT_SALES_RATIO_PERCENTAGE || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.PRODUCT_SALES_DIFFERENCE || "",
            ratio_percentage:
              this.YoYKpiData?.PRODUCT_SALES_RATIO_PERCENTAGE || "",
          },
        },
      ];

      return {
        name: "Product Sales",
        values: values,
      };
    },
    unitsReturned(): KPIGetterWrapper {
      const values: KPIValue[] = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.RETURN_SALES_DIFFERENCE || "",
            ratio_percentage:
              this.PoPKpiData?.RETURN_SALES_RATIO_PERCENTAGE || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.RETURN_SALES_DIFFERENCE || "",
            ratio_percentage:
              this.WoWKpiData?.RETURN_SALES_RATIO_PERCENTAGE || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.RETURN_SALES_DIFFERENCE || "",
            ratio_percentage:
              this.MoMKpiData?.RETURN_SALES_RATIO_PERCENTAGE || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.RETURN_SALES_DIFFERENCE || "",
            ratio_percentage:
              this.YoYKpiData?.RETURN_SALES_RATIO_PERCENTAGE || "",
          },
        },
      ];

      return {
        name: "Return Sales",
        values: values,
      };
    },
  },
});
