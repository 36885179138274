export const replaceController = (index: number, array: AbortController[]) => {
  array[index].abort();
  const newController = new AbortController();
  array[index] = newController;
  return newController;
};

export const getAbortSignal = (controllersArray: AbortController[]): AbortSignal => {
  const controller = new AbortController();
  controllersArray.push(controller);
  return controller.signal;
};

export const replaceSingleController = (variable: AbortController) => {
  variable.abort();
  const newController = new AbortController();
  return newController;
};
