import { DateTime } from "luxon";

import type { Channels } from "@/store/main";
import type { requestParams } from "@/types/requests";
import type { TableRow, TableHeader } from "@/types/table";

export const formatCurrency = (
  amount: number,
  isNegativeSymbolBeforeCurrency: boolean = true,
  showDecimals: boolean = false,
) => {
  const sign = amount < 0 ? "-" : "";
  const absAmount = Math.abs(amount);

  const formattedAmount = showDecimals
    ? absAmount.toFixed(2)
    : absAmount.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

  return (
    (isNegativeSymbolBeforeCurrency && sign ? `${sign}$` : "$") +
    formattedAmount +
    (!isNegativeSymbolBeforeCurrency && sign ? `${sign}$` : "")
  );
};

export const formatComma = (amount: number) => {
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const formatNumber = (amount: number) => {
  if (amount >= 1000 && amount < 10000) {
    return (amount / 1000).toFixed(1) + "k";
  } else if (amount >= 10000) {
    return Math.round(amount / 1000) + "k";
  } else {
    return amount.toLocaleString("en");
  }
};

export const formatOldFormatOfDateToAmericanFormat = (date: any) => {
  const formattedDate = DateTime.fromISO(date).toFormat("LL/dd/yyyy");

  return formattedDate;
};

// Formatting row values for table
export const formattingValue = (
  data: string | number,
  row: TableRow,
  col: TableHeader,
  isShouldTruncate = true,
) => {
  if (data === null) {
    return "";
  }

  if (col.capitalizeFirstLetterOfEachWord) {
    return data === null ? "" : capitalizeFirstLetterOfEachWord(data as string);
  }

  if (
    col?.currency &&
    !col?.roundedWithTwoDecimalsAndMultiply &&
    !col?.roundedToTwoDecimals &&
    !col?.addComma
  ) {
    return formatCurrency(data as number);
  }

  if (
    col?.roundedWithTwoDecimalsAndMultiply &&
    !col?.currency &&
    !col?.roundedToTwoDecimals &&
    !col?.addComma
  ) {
    return ((data as number) * 100).toFixed(2);
  }

  if (
    col?.roundedToTwoDecimals &&
    !col?.currency &&
    !col?.roundedWithTwoDecimalsAndMultiply &&
    !col?.addComma
  ) {
    return (data as number).toFixed(2);
  }

  if (
    col?.addComma &&
    !col?.currency &&
    !col?.roundedWithTwoDecimalsAndMultiply &&
    !col?.roundedToTwoDecimals
  ) {
    return formatComma(data as number);
  }

  if (
    col?.roundedWithZeroDecimalsAndMultiply &&
    !col?.currency &&
    !col?.roundedWithTwoDecimalsAndMultiply &&
    !col?.roundedToTwoDecimals &&
    !col?.addComma
  ) {
    return ((data as number) * 100).toFixed(0);
  }

  if (
    col?.roundedWithZeroDecimals &&
    !col?.currency &&
    !col?.roundedWithTwoDecimalsAndMultiply &&
    !col?.roundedToTwoDecimals &&
    !col?.addComma
  ) {
    return Number(data).toFixed(0);
  }

  if (col?.value === "DATE" || col.date) {
    if (col.date) {
      return formatOldFormatOfDateToAmericanFormat(row[col.value]);
    }
    if (row["DATE"]) {
      return formatOldFormatOfDateToAmericanFormat(row["DATE"]);
    } else {
      return "Selected period";
    }
  }
  if (
    !!col?.maxLength &&
    isShouldTruncate &&
    !col?.currency &&
    !col?.roundedWithTwoDecimalsAndMultiply &&
    !col?.roundedToTwoDecimals
  ) {
    return truncateString(data as string, col.maxLength);
  }

  if (col.nullToEmptyString) {
    return data ?? "";
  }

  if (col.currencyWithTwoDecimals) {
    return "$" + (data as number).toFixed(2);
  }

  return data;
};

export const truncateString = (str: string, maxLength: number) => {
  if (str?.length > maxLength) {
    return str.slice(0, maxLength - 1) + "...";
  }
  return str;
};

export const checkToParams = (params: requestParams) => {
  let findedParams = "";
  let isFirstParam = true;

  for (const key of Object.keys(params)) {
    if (params[key as keyof requestParams] !== "") {
      if (isFirstParam) {
        findedParams += `?${key}=${params[key as keyof requestParams]}`;
        isFirstParam = false;
      } else {
        findedParams += `&${key}=${params[key as keyof requestParams]}`;
      }
    }
  }

  return findedParams;
};

export const formattingReasons = (reason: string) => {
  switch (reason) {
    case "DEFECTIVE":
      return "Defective";
    case "UNWANTED_ITEM":
      return "Unwanted Item";
    case "ORDERED_WRONG_ITEM":
      return "Ordered Wrong Item";
    case "NOT_AS_DESCRIBED":
      return "Not As Described";
    case "DAMAGED_BY_FC":
      return "Damaged by FC";
    case "QUALITY_UNACCEPTABLE":
      return "Quality Unacceptable";
    case "FOUND_BETTER_PRICE":
      return "Found Better Price";
    case "SWITCHEROO":
      return "Switcheroo";
    case "DAMAGED_BY_CARRIER":
      return "Damaged by Carrier";
    case "MISSED_ESTIMATED_DELIVERY":
      return "Missed Estimated Delivery";
    case "UNDELIVERABLE_UNKNOWN":
      return "Undeliverable Unknown";
    case "UNDELIVERABLE_REFUSED":
      return "Undeliverable Refused";
    case "UNAUTHORIZED_PURCHASE":
      return "Unauthorized Purchase";
    case "MISSING_PARTS":
      return "Missing Parts";
    case "APPAREL_TOO_SMALL":
      return "Apparel Too Small";
    case "NEVER_ARRIVED":
      return "Never Arrived";
    case "APPAREL_TOO_LARGE":
      return "Apparel Too Large";
    case "NOT_COMPATIBLE":
      return "Not Compatible";
    case "MISORDERED":
      return "Misordered";
    case "NO_REASON_GIVEN":
      return "No Reason Given";
    case "UNDELIVERABLE_CARRIER_MISS_SORTED":
      return "Undeliverable Carrier Miss Sorted";
    case "EXTRA_ITEM":
      return "Extra Item";
  }
};

export const capitalizeFirstLetterOfEachWord = (string: string) => {
  return string
    .replace(/[_-]/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const renameChannels = (channels: Channels[], channel: number) => {
  return channels.find((item) => item.CHANNEL_ID === channel)?.NAME;
};
