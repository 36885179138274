import { Permissions } from "@/types/permissions";
import { capitalizeFirstLetterOfEachWord } from "./formatters";

export const permissionRename = (name: string) => {
  if (name.includes("report:read:")) {
    return capitalizeFirstLetterOfEachWord(
      name.replace("report:read:", "Reports: "),
    );
  }

  if (name.includes("report:edit:")) {
    return name.replace("report:edit:", "Can edit reports: ");
  }

  if (name.includes("ADMIN")) {
    return "Administrator";
  }

  return name;
};

export const hasPermission = (
  permissionsList: string[],
  permission: string,
) => {
  const permissionCheck = permissionsList?.includes(permission);

  const isAdmin = permissionsList?.includes(Permissions.ADMIN);

  return !permissionCheck && !isAdmin;
};

export const checkInsightful = (permissions: string[]) => {
  return (
    permissions?.includes(Permissions.SALES_REPORT) &&
    permissions?.includes(Permissions.ADS_SUMMARY) &&
    permissions?.includes(Permissions.REVIEW_REPORT) &&
    permissions?.includes(Permissions.BRAND_PROTECTION)
  );
};
