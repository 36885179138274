<script setup lang="ts">
import { useColorMode } from "@vueuse/core";

const colorMode = useColorMode();

const changeColorMode = (mode: "light" | "dark" | "auto") => {
  colorMode.value = mode;
};

const themeList: {
  theme: "light" | "dark" | "auto";
  icon: string;
  text: string;
}[] = [
  {
    theme: "light",
    icon: "sun",
    text: "Light",
  },
  {
    theme: "dark",
    icon: "moon",
    text: "Dark",
  },
];
</script>

<template>
  <section class="flex w-full theme-wrapper">
    <div
      v-for="theme in themeList"
      :key="theme.theme"
      @click="changeColorMode(theme.theme)"
      class="items"
      :class="{
        selected: colorMode === theme.theme,
        'not-selected': colorMode !== theme.theme,
      }"
    >
      <span>
        <feather-icon :type="theme.icon" size="25px" />
      </span>
      <span> {{ theme.text }} </span>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.theme-wrapper {
  & > div:first-of-type {
    @apply rounded-l-lg;
  }

  & > div:last-of-type {
    @apply rounded-r-lg;
  }
}

.items {
  @apply cursor-pointer font-light flex items-center gap-2 transition-all duration-500 w-full;

  i {
    @apply rounded-full p-2 border-none;
  }

  span {
    @apply flex items-center;
  }
}

.items:hover i {
  @apply rounded-full p-2 border-none  bg-primary text-white transition-all duration-500;
}

.selected {
  @apply bg-primary text-white;
}

.not-selected {
  @apply bg-hover text-secondaryText;
}
</style>
