<template>
  <svg
    width="227"
    height="227"
    viewBox="0 0 227 227"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M71.3062 56.1082C71.3062 56.1082 97.378 75.8926 137.014 38.899C172.215 6.04519 200.956 57.8811 201.157 82.7042C201.418 114.861 165.957 140.589 183.166 161.71C200.375 182.831 149.038 217.707 121.369 187.524C86.9509 149.976 77.6263 180.484 58.0082 180.484C43.9279 180.484 15.0197 145.498 34.5411 119.469C50.9681 97.5667 42.0094 90.2976 37.67 82.7042C31.4122 71.753 46.2747 42.028 71.3062 56.1082Z"
      fill="#DEE9FF"
    />
    <path
      d="M113.489 101.094V174.512L56.4861 149.891L56.758 76.7379L113.489 101.094Z"
      fill="#418DF9"
    />
    <path
      d="M113.49 101.138V174.556L170.216 150.785V76.9723L113.49 101.138Z"
      fill="#A2BDFF"
    />
    <path
      d="M113.489 101.094L170.493 77.2181L113.937 52.1485L56.4861 76.6212L113.489 101.094Z"
      fill="#699CFF"
    />
    <path
      d="M77.9741 67.4678L134.76 92.1846L135.276 110.196L151.164 103.641L150.684 85.5151L92.2176 61.4004L77.9741 67.4678Z"
      fill="#418DF9"
    />
    <path
      d="M131.526 127.479C132.426 127.479 133.156 126.147 133.156 124.505C133.156 122.862 132.426 121.53 131.526 121.53C130.626 121.53 129.897 122.862 129.897 124.505C129.897 126.147 130.626 127.479 131.526 127.479Z"
      fill="white"
    />
    <path
      d="M157.78 116.783C158.68 116.783 159.409 115.451 159.409 113.808C159.409 112.166 158.68 110.834 157.78 110.834C156.88 110.834 156.15 112.166 156.15 113.808C156.15 115.451 156.88 116.783 157.78 116.783Z"
      fill="white"
    />
    <path
      d="M140.97 143.182L139.911 142.771C142.256 136.735 145.196 133.409 148.65 132.887C151.955 132.388 154.738 134.664 155.748 135.938L154.858 136.643C154.16 135.762 151.714 133.572 148.82 134.01C145.84 134.46 143.125 137.632 140.97 143.182Z"
      fill="white"
    />
  </svg>
</template>
