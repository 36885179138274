import axiosIns from "@/utils/axios";

export const login = (email: string, password: string) => {
  return axiosIns.post(
    "/auth/login",
    {
      email,
      password,
    },
    { withCredentials: true },
  );
};

export const logout = () => {
  return axiosIns.post("/auth/logout", {}, { withCredentials: true });
};

export const getRefreshToken = () => {
  return axiosIns.post("/auth/refresh", {}, { withCredentials: true });
};

export const getUser = () => {
  return axiosIns.get("/auth/users/me");
};

export const passwordChange = (
  email: string,
  password: string,
  newPassword: string,
  newPasswordConfirmation: string,
) => {
  return axiosIns.patch("/auth/password", {
    email,
    password,
    newPassword,
    newPasswordConfirmation,
  });
};
