import axios from "@/utils/axios";

import type { requestParams, PaginatedWrapper } from "@/types/requests";
import type {
  productReturnsTable,
  ProductReturnsKPITotals,
  ProductReturnsKPIData,
  ProductReturnsChart,
} from "@/types/productReturns";

export const getProductReturnsChart = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ProductReturnsChart[]>("/returns/chart", params, options);
};

export const getProductReturnsKPI = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ProductReturnsKPIData>("/returns/kpi", params, options);
};

export const getProductReturnsKPITotals = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ProductReturnsKPITotals>(
    "/returns/kpi/totals",
    params,
    options,
  );
};

export const getProductReturnsTable = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<productReturnsTable[]>>(
    "/returns/table",
    params,
    options,
  );
};

export const getTopReasonsTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<any[]>("/returns/top-reasons", params, options);
};

export const getReasonsTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<any[]>>(
    "/returns/reasons-table",
    params,
    options,
  );
};
