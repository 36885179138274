import { defineStore } from 'pinia';

import type { requestParams } from '@/types/requests';
import type { TableHeader, TableRow } from '@/types/table';
import { getPerformanceTableApi } from '@/api/productPerformance';
import { replaceSingleController } from '@/utils/createAbortControllers';
import type { ProductPerformanceStore } from '@/types/productPerformance';

let tableLowAbort = new AbortController();
let tableHighAbort = new AbortController();

export const useProductPerformanceStore = defineStore('productPerformance', {
  state: (): ProductPerformanceStore => ({
    highTableData: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    lowTableData: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    isHighTableLoader: true,
    isHighTableTotalsLoader: true,
    isLowTableLoader: true,
    isLowTableTotalsLoader: true,
    highMode: 'PERC',
    lowMode: 'PERC',
  }),

  actions: {
    abortLowTable() {
      tableLowAbort = replaceSingleController(tableLowAbort);
    },

    abortHighTable() {
      tableHighAbort = replaceSingleController(tableHighAbort);
    },

    async getPerformanceTable(
      params: requestParams,
      isLow: boolean,
      callback?: (data: TableRow[], headers?: TableHeader[]) => void,
    ) {
      if (isLow) {
        if (params.limit === 0) {
          this.isLowTableTotalsLoader = true;
        } else {
          this.isLowTableLoader = true;
        }
      } else {
        if (params.limit === 0) {
          this.isHighTableTotalsLoader = true;
        } else {
          this.isHighTableLoader = true;
        }
      }

      try {
        const { data } = await getPerformanceTableApi(params, {
          signal: isLow ? tableLowAbort.signal : tableHighAbort.signal,
        });
        if (params.limit === 0) {
          callback &&
            callback(data.data, [
              {
                name: 'Revenue Change #',
                value: 'REVENUE_DIF',
                isNumber: true,
                addComma: true,
                roundedWithZeroDecimals: true,
              },
              {
                name: 'Revenue Change %',
                value: 'REVENUE_PERC',
                isPercentage: true,
                dividedForXlsx: true,
                roundedToTwoDecimals: true,
              },
              {
                name: 'Session Change #',
                value: 'SESSIONS_DIF',
                isNumber: true,
                addComma: true,
                roundedWithZeroDecimals: true,
              },
              {
                name: 'Session Change %',
                value: 'SESSIONS_PERC',
                isPercentage: true,
                dividedForXlsx: true,
                roundedToTwoDecimals: true,
              },
              {
                name: 'Conversion Change #',
                value: 'CONVERSION_DIF',
                isNumber: true,
                addComma: true,
                roundedWithZeroDecimals: true,
              },
              {
                name: 'Conversion Change %',
                value: 'CONVERSION_PERC',
                isPercentage: true,
                dividedForXlsx: true,
                roundedToTwoDecimals: true,
              },
              {
                name: 'Weighted Buy Box Change #',
                value: 'BUY_BOX_DIF',
                isNumber: true,
                addComma: true,
                roundedWithZeroDecimals: true,
              },
              {
                name: 'Weighted Buy Box Change %',
                value: 'BUY_BOX_PERC',
                isPercentage: true,
                dividedForXlsx: true,
                roundedToTwoDecimals: true,
              },
              {
                name: 'Ad Sales Change #',
                value: 'AD_SALES_DIF',
                isNumber: true,
                addComma: true,
                roundedWithZeroDecimals: true,
              },
              {
                name: 'Ad Sales Change %',
                value: 'AD_SALES_PERC',
                isPercentage: true,
                dividedForXlsx: true,
                roundedToTwoDecimals: true,
              },
              {
                name: 'Ad Spend Change #',
                value: 'AD_SPEND_DIF',
                isNumber: true,
                addComma: true,
                roundedWithZeroDecimals: true,
              },
              {
                name: 'Ad Spend Change %',
                value: 'AD_SPEND_PERC',
                isPercentage: true,
                dividedForXlsx: true,
                roundedToTwoDecimals: true,
              },
              {
                name: 'FBA Days Out of Stock Change #',
                value: 'FBA_DAYS_DIF',
                isNumber: true,
                addComma: true,
                roundedWithZeroDecimals: true,
              },
              {
                name: 'FBA Days Out of Stock Change %',
                value: 'FBA_DAYS_PERC',
                isPercentage: true,
                dividedForXlsx: true,
                roundedToTwoDecimals: true,
              },
            ]);
        } else {
          if (isLow) {
            this.lowTableData.data = data.data;
            this.lowTableData.pagination = data.pagination;
          } else {
            this.highTableData.data = data.data;
            this.highTableData.pagination = data.pagination;
          }
        }
        if (isLow) {
          this.isLowTableLoader = false;
          this.isLowTableTotalsLoader = false;
        } else {
          this.isHighTableLoader = false;
          this.isHighTableTotalsLoader = false;
        }
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        if (isLow) {
          this.isLowTableLoader = false;
          this.isLowTableTotalsLoader = false;
        } else {
          this.isHighTableLoader = false;
          this.isHighTableTotalsLoader = false;
        }
        if (errorData.status === 'canceled') {
          if (isLow) {
            this.isLowTableLoader = true;
          } else {
            this.isHighTableLoader = true;
          }
        }
      }
    },

    changeMode(mode: 'PERC' | 'DIF', isLow: boolean) {
      if (isLow) {
        this.lowMode = mode;
      } else {
        this.highMode = mode;
      }
    },

    cleanStore() {
      this.highTableData = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
      this.lowTableData = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
      this.isHighTableLoader = true;
      this.isHighTableTotalsLoader = true;
      this.isLowTableLoader = true;
      this.isLowTableTotalsLoader = true;
    },
  },

  getters: {},
});
