<script setup lang="ts">
type Props = {
  list: string[];
  currentItem: string;
};

type Emits = {
  (event: "onChange", value: string): void;
  (event: "update:modelValue", value: string): void;
};

const props = defineProps<Props>();

const emit = defineEmits<Emits>();
</script>

<template>
  <div class="granularity-wrapper">
    <div
      v-for="item in props.list"
      :key="item"
      :class="{ 'granularity-selected': currentItem === item }"
      class="granularity-item"
      @click="emit('update:modelValue', item)"
    >
      {{ item }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.granularity-wrapper {
  @apply flex justify-center items-center h-[40px];
}

.granularity-item {
  @apply h-full  flex items-center justify-center px-3 min-w-[80px] bg-cardBg shadow-lg font-normal text-mainText cursor-pointer;
}

.granularity-item:first-of-type {
  @apply rounded-l-md shadow-md dark:shadow-slate-900/50;
}

.granularity-item:last-of-type {
  @apply rounded-r-md shadow-md dark:shadow-slate-900/50;
}

.granularity-selected {
  @apply bg-hover text-primary font-bold;
}
</style>
