import { defineStore } from "pinia";

import {
  getBrandSales,
  getBrandsKPI,
  getTopProducts as getTopProductsApi,
  getTotalValues,
} from "@/api/salesAndTraffic";

import type { TableHeader, TableRow } from "@/types/table";
import type { requestParams } from "@/types/requests";
import type { SalesAndTraffic } from "@/types/salesAndTraffic/types";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers";

const controllers: AbortController[] = [];
let tableController = new AbortController();
let salesTableController = new AbortController();

export const useSalesAndTrafficStore = defineStore("sales-and-traffic", {
  state: (): SalesAndTraffic => ({
    filters: {
      granularity: "MONTH",
    },
    KPITotal: null,
    KPIDetails: null,
    WoWKpiData: null,
    MoMKpiData: null,
    YoYKpiData: null,
    PoPKpiData: null,
    salesChart: [],
    salesTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    chartKey: { name: "Revenue", value: "SALES_TOTAL" },
    brandSalesTable: [],
    topProductsTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    periodValues: [],
    isKpiLoader: true,
    isChartLoader: true,
    isTopProductsLoading: true,
    isBrandSalesTableLoading: true,
    isTopProductsTotalLoading: true,
    isBrandSalesTableTotalLoading: false,
  }),

  actions: {
    abortRequestsSalesAndTrafficStore() {
      this.clearStore();
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortTopProductsRequest() {
      tableController = replaceSingleController(tableController);
    },

    abortSalesTableRequest() {
      salesTableController = replaceSingleController(salesTableController);
    },

    updateGranularity(payload: "MONTH" | "WEEK") {
      this.filters.granularity = payload;
    },
    async getReviewsKpiTotal(params: requestParams) {
      try {
        const res = await getTotalValues(params, {
          signal: getAbortSignal(controllers),
        });
        this.KPITotal = res.data;
      } catch (err) {
        /* empty */
      }
    },

    async getKpiDetails(
      params: requestParams,
      type?: "WoWKpiData" | "MoMKpiData" | "YoYKpiData" | "PoPKpiData",
    ) {
      try {
        const { data } = await getBrandsKPI(params, {
          signal: getAbortSignal(controllers),
        });
        if (type) {
          this[type] = null;
          this[type] = data;
        } else {
          this.KPIDetails = data;
        }
        // eslint-disable-next-line no-empty
      } finally {
      }
    },

    updateKPILoader(value: boolean) {
      this.isKpiLoader = value;
    },

    async getChartSales(params: requestParams) {
      this.isChartLoader = true;
      try {
        const { data } = await getBrandSales(params, {
          signal: getAbortSignal(controllers),
        });

        const newFormat = data.data.map((item) => {
          return {
            ...item,
            BUY_BOX_PERCENTAGE: item.BUY_BOX_PERCENTAGE * 100,
            CONVERSION: item.CONVERSION * 100,
          };
        });
        this.salesChart = newFormat;
      } finally {
        this.isChartLoader = false;
      }
    },
    setChartValue(value: { name: string; value: string }) {
      this.chartKey = value;
    },
    async getBrandSalesTable(params: requestParams) {
      this.isBrandSalesTableLoading = true;

      try {
        const { data } = await getBrandSales(params, {
          signal: salesTableController.signal,
        });
        this.salesTable.data = data.data;
        this.salesTable.pagination = data.pagination;
        this.isBrandSalesTableLoading = false;
      } catch (err: any) {
        const errorData = JSON.parse(err?.message);
        this.isBrandSalesTableLoading = false;
        if (errorData.status === "canceled") {
          this.isBrandSalesTableLoading = true;
        }
      }
    },

    async getAllBrandSalesTable(
      params: requestParams,
      callback?: (
        data: TableRow[],
        headers?: TableHeader[],
        custom?: boolean,
      ) => void,
      granularity?: "MONTH" | "WEEK",
    ) {
      this.isBrandSalesTableTotalLoading = true;
      try {
        const { data } = await getBrandSales(params, {
          signal: getAbortSignal(controllers),
        });

        callback &&
          callback(
            data.data,
            [
              {
                name: granularity === "MONTH" ? "Month Start" : "Week Start",
                value: "DATE",
                fixed: true,
                loaderWidth: "100px",
              },
              {
                name: "Brand Name",
                value: "BRAND_NAME",
              },
              {
                name: "Revenue",
                value: "SALES_TOTAL",
                isPercentage: false,
                sortable: true,
                currencySimbol: "$",
                loaderWidth: "130px",
                isNumber: true,
                addComma: true,
              },
              {
                name: "Revenue Change %",
                value: "SALES_TOTAL_DELTA",
                isPercentage: true,
                roundedToTwoDecimals: true,
                dividedForXlsx: true,
                loaderWidth: "160px",
              },
              {
                name: "Total Order Items",
                value: "TOTAL_ORDER_ITEMS",
                isPercentage: false,
                isNumber: true,
                addComma: true,
                loaderWidth: "150px",
              },
              {
                name: "Total Order Items Change %",
                value: "TOTAL_ORDER_ITEMS_DELTA",
                isPercentage: true,
                roundedToTwoDecimals: true,
                dividedForXlsx: true,
              },
              {
                name: "Units Ordered",
                value: "UNITS_ORDERED",
                isPercentage: false,
                isNumber: true,
                addComma: true,
              },
              {
                name: "Units Ordered Change %",
                value: "UNITS_ORDERED_DELTA",
                isPercentage: true,
                roundedToTwoDecimals: true,
                dividedForXlsx: true,
              },
              {
                name: "Sessions",
                value: "SESSIONS_TOTAL",
                isPercentage: false,
                isNumber: true,
                addComma: true,
              },
              {
                name: "Sessions Change %",
                value: "SESSIONS_TOTAL_DELTA",
                isPercentage: true,
                roundedToTwoDecimals: true,
                dividedForXlsx: true,
              },
              {
                name: "Conversion",
                value: "CONVERSION",
                isPercentage: true,
                roundedWithTwoDecimalsAndMultiply: true,
              },
              {
                name: "Conversion Change %",
                value: "CONVERSION_DELTA",
                isPercentage: true,
                roundedToTwoDecimals: true,
                dividedForXlsx: true,
              },
              {
                name: "Page Views",
                value: "PAGE_VIEWS_TOTAL",
                isPercentage: false,
                isNumber: true,
                addComma: true,
              },
              {
                name: "Page Views Change %",
                value: "PAGE_VIEWS_TOTAL_DELTA",
                isPercentage: true,
                roundedToTwoDecimals: true,
                dividedForXlsx: true,
              },
              {
                name: "Weighted Buy Box",
                value: "BUY_BOX_PERCENTAGE",
                isPercentage: true,
                roundedWithTwoDecimalsAndMultiply: true,
              },
              {
                name: "Weighted Buy Box Change %",
                value: "BUY_BOX_PERCENTAGE_DELTA",
                isPercentage: true,
                roundedToTwoDecimals: true,
                dividedForXlsx: true,
              },
            ],
            true,
          );

        this.isBrandSalesTableTotalLoading = false;
        this.isBrandSalesTableLoading = false;
      } catch (err) {
        // eslint-disable-next-line no-empty
      }
    },

    getTopProducts(
      params: requestParams,
      callback?: (
        data: TableRow[],
        headers?: TableHeader[],
        customHeaders?: boolean,
      ) => void,
      granularity?: "MONTH" | "WEEK",
    ) {
      if (params.limit === 0) {
        this.isTopProductsTotalLoading = true;
      } else {
        this.isTopProductsLoading = true;
      }
      getTopProductsApi(params, {
        signal: tableController.signal,
      })
        .then(({ data }) => {
          if (params.limit === 0) {
            callback &&
              callback(
                data?.DATA,
                [
                  {
                    name:
                      granularity === "MONTH" ? "Month Start" : "Week Start",
                    value: "DATE",
                    sortable: true,
                  },
                  {
                    name: "Brand Name",
                    value: "BRAND_NAME",
                  },
                  {
                    name: "Product Name",
                    value: "PRODUCT_NAME",
                    sortable: true,
                    maxLength: 20,
                  },
                  { name: "SKU", value: "SKU", width: "150" },
                  { name: "ASIN", value: "ASIN", width: "130" },
                  {
                    name: "Revenue",
                    value: "SALES",
                    currency: true,
                    isNumber: true,
                    sortable: true,
                    width: "130",
                  },
                  {
                    name: "Revenue B2B",
                    value: "ORDERED_PRODUCT_SALES_B2B",
                    currency: true,
                    isNumber: true,
                  },
                  {
                    name: "Units Ordered",
                    value: "UNITS_ORDERED",
                    isNumber: true,
                    addComma: true,
                  },
                  {
                    name: "Units Ordered B2B",
                    value: "UNITS_ORDERED_B2B",
                    isNumber: true,
                    addComma: true,
                  },
                  {
                    name: "Page Views",
                    value: "PAGE_VIEWS_TOTAL",
                    isNumber: true,
                    addComma: true,
                  },
                  {
                    name: "Sessions",
                    value: "SESSIONS_TOTAL",
                    isNumber: true,
                    addComma: true,
                  },
                  {
                    name: "Total Order Items",
                    value: "TOTAL_ORDER_ITEMS",
                    isNumber: true,
                    addComma: true,
                  },
                  {
                    name: "Total Order Items B2B",
                    value: "TOTAL_ORDER_ITEMS_B2B",
                    isNumber: true,
                    addComma: true,
                  },
                  {
                    name: "Weighted Buy Box",
                    value: "BUY_BOX_PERCENTAGE",
                    isPercentage: true,
                    roundedWithZeroDecimalsAndMultiply: true,
                  },
                ],
                true,
              );
          } else {
            this.topProductsTable.data = data?.DATA;
            this.topProductsTable.pagination = data?.PAGINATION;
          }

          this.isTopProductsLoading = false;
          this.isTopProductsTotalLoading = false;
        })
        .catch((err: any) => {
          const errorData = JSON.parse(err?.message);
          this.isTopProductsLoading = false;
          this.isTopProductsTotalLoading = false;
          if (errorData.status === "canceled") {
            this.isTopProductsLoading = true;
          }
        });
    },

    clearStore() {
      this.filters = {
        granularity: "MONTH",
      };
      this.KPITotal = null;
      this.KPIDetails = null;
      this.WoWKpiData = null;
      this.MoMKpiData = null;
      this.YoYKpiData = null;
      this.PoPKpiData = null;
      this.salesChart = [];
      this.chartKey = { name: "Revenue", value: "SALES_TOTAL" };
      this.brandSalesTable = [];
      this.topProductsTable = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
      this.periodValues = [];
      this.isKpiLoader = true;
      this.isChartLoader = true;
      this.isTopProductsLoading = true;
      this.isBrandSalesTableLoading = true;
      this.isTopProductsTotalLoading = true;
      this.isBrandSalesTableTotalLoading = false;
    },
  },

  getters: {
    salesKpiValue() {
      const values: {
        name: string;
        value: { difference: number; ratio_percentage: number } | undefined;
      }[] = [
        { name: "PoP", value: this.PoPKpiData?.Sales },
        { name: "WoW", value: this.WoWKpiData?.Sales },
        { name: "MoM", value: this.MoMKpiData?.Sales },
        { name: "YoY", value: this.YoYKpiData?.Sales },
      ];

      return {
        name: "Sales",
        values: values,
      };
    },
    sessionsKpiValue() {
      const values: {
        name: string;
        value: { difference: number; ratio_percentage: number } | undefined;
      }[] = [
        { name: "PoP", value: this.PoPKpiData?.Sessions },
        { name: "WoW", value: this.WoWKpiData?.Sessions },
        { name: "MoM", value: this.MoMKpiData?.Sessions },
        { name: "YoY", value: this.YoYKpiData?.Sessions },
      ];

      return {
        name: "Sessions",
        values: values,
      };
    },
    conversionKpiValue() {
      const values: {
        name: string;
        value: { difference: number; ratio_percentage: number } | undefined;
      }[] = [
        { name: "PoP", value: this.PoPKpiData?.Conversion },
        { name: "WoW", value: this.WoWKpiData?.Conversion },
        { name: "MoM", value: this.MoMKpiData?.Conversion },
        { name: "YoY", value: this.YoYKpiData?.Conversion },
      ];

      return {
        name: "Conversion",
        values: values,
      };
    },
    brandSalesTableRows(): TableRow[] {
      if (!this.brandSalesTable || this.brandSalesTable.length === 0) {
        return [];
      }

      const result = [];

      function calculateDelta(current: any, prior: any) {
        if (prior === 0) return 0;
        return (current - prior) / prior;
      }

      function formatCurrency(amount: any) {
        return (
          "$" +
          amount.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        );
      }

      for (let i = 1; i < this.brandSalesTable.length; i++) {
        const currentData = this.brandSalesTable[i];
        const priorData = this.brandSalesTable[i - 1];

        const currentSales = currentData.SALES_TOTAL;

        result.push({
          DATE: currentData.DATE,
          SALES_TOTAL: formatCurrency(currentSales),
          SALES_TOTAL_DELTA: calculateDelta(
            currentSales,
            priorData.SALES_TOTAL,
          ),
          TOTAL_ORDER_ITEMS: currentData.TOTAL_ORDER_ITEMS,
          TOTAL_ORDER_ITEMS_DELTA: calculateDelta(
            currentData.TOTAL_ORDER_ITEMS,
            priorData.TOTAL_ORDER_ITEMS,
          ),
          UNITS_ORDERED: currentData.UNITS_ORDERED,
          UNITS_ORDERED_DELTA: calculateDelta(
            currentData.UNITS_ORDERED,
            priorData.UNITS_ORDERED,
          ),
          SESSIONS_TOTAL: currentData.SESSIONS_TOTAL,
          SESSIONS_TOTAL_DELTA: calculateDelta(
            currentData.SESSIONS_TOTAL,
            priorData.SESSIONS_TOTAL,
          ),
          CONVERSION: currentData.CONVERSION,
          CONVERSION_DELTA: calculateDelta(
            currentData.CONVERSION,
            priorData.CONVERSION,
          ),
          PAGE_VIEWS_TOTAL: currentData.PAGE_VIEWS_TOTAL,
          PAGE_VIEWS_TOTAL_DELTA: calculateDelta(
            currentData.PAGE_VIEWS_TOTAL,
            priorData.PAGE_VIEWS_TOTAL,
          ),
          BUY_BOX_PERCENTAGE: currentData.BUY_BOX_PERCENTAGE,
          BUY_BOX_PERCENTAGE_DELTA: calculateDelta(
            currentData.BUY_BOX_PERCENTAGE,
            priorData.BUY_BOX_PERCENTAGE,
          ),
        });
      }

      return result.sort((a: any, b: any) => {
        return new Date(b?.DATE)?.getTime() - new Date(a?.DATE)?.getTime();
      });
    },
  },
});
