export enum RouteNames {
  LOGIN = "LoginPage",
  PASSWORD_CHANGE = "PasswordChange",
  SALES_AND_TRAFFIC = "SalesAndTraffic",
  FORGET_PASSWORD = "ForgetPassword",
  PRODUCT_RETURNS = "ProductReturns",
  ADVERTISING_PERFORMANCE = "AdvertisingPerformance",
  REVIEWS = "Reviews",
  BRAND_PROTECTION = "BrandProtection",
  CAMPAIGNS = "Campaigns",
  ADVERTISING_SUMMARY = "AdvertisingSummary",
  USERS = "Users",
  EDIT_USER = "EditUser",
  NOT_FOUND = "NotFound",
  DONT_HAVE_PERMISSION = "DontHavePermission",
  REST_PASSWORD = "ResetPassword",
  SOINSIGHTFUL = "SOInsightful",
  INVENTORY = "Inventory",
  ALERTS = "ProductAlerts",
  PERFORMANCE = "ProductPerformance",
  HEALTH = "AccountHealth",
}

export type PermissionsType =
  | "ADMIN"
  | "report:read:sales-report"
  | "report:read:returns"
  | "report:read:reviews-report"
  | "report:read:ads-summary"
  | "report:read:brand-protection"
  | "report:read:campaigns-report"
  | "report:read:inventory-report"
  | "report:read:product-alerts"
  | "report:read:product-performance"
  | "report:read:account-health";

export const RoutePathsWithPermissions: { [key in PermissionsType]: string } = {
  ADMIN: "/insightful",
  "report:read:sales-report": "/sales-and-traffic",
  "report:read:returns": "/product-returns",
  "report:read:ads-summary": "/advertising-summary",
  "report:read:reviews-report": "/reviews",
  "report:read:brand-protection": "/brand-protection",
  "report:read:campaigns-report": "/campaigns",
  "report:read:inventory-report": "/inventory",
  "report:read:product-alerts": "/product-alerts",
  "report:read:product-performance": "/product-performance",
  "report:read:account-health": "/account-health",
};

export const ArrayOfPriorityRoutes = [
  { path: "/insightful", permission: "" },
  { path: "/sales-and-traffic", permission: "report:read:sales-report" },
  { path: "/product-returns", permission: "report:read:returns" },
  { path: "/advertising-summary", permission: "report:read:ads-summary" },
  { path: "/reviews", permission: "report:read:reviews-report" },
  { path: "/brand-protection", permission: "report:read:brand-protection" },
  { path: "/campaigns", permission: "report:read:campaigns-report" },
  { path: "/product-alerts", permission: "report:read:product-alerts" },
  {
    path: "/product-performance",
    permission: "report:read:product-performance",
  },
  { path: "/account-health", permission: "report:read:account-health" },
  { path: "/users", permission: "ADMIN" },
];

export const findIconForPermission = (permission: string) => {
  switch (permission) {
    case "report:read:ads-summary":
      return "zap";
    case "report:read:brand-protection":
      return "lock";
    case "report:read:returns":
      return "truck";
    case "report:read:reviews-report":
      return "message-square";
    case "report:read:sales-report":
      return "dollar-sign";
    case "report:read:campaigns-report":
      return "edit";
    case "report:read:inventory-report":
      return "box";
    case "report:read:product-alerts":
      return "bell";
    case "report:read:product-performance":
      return "bar-chart";
    case "report:read:account-health":
      return "heart";
    default:
      return "home";
  }
};
