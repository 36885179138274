import { defineStore } from 'pinia';

import { useReviewsStore } from './reviews';
import { useInventoryStore } from './inventory';
import { useProductAlertsStore } from './product-alerts';
import { useProductReturnsStore } from './product-returns';
import { useSalesAndTrafficStore } from './sales-and-traffic';
import { useFunnelLevelStore } from './ads-summary/funnelLevel';
import { useProductPerformanceStore } from './product-performance';
import { useAdsSummaryAwarenessStore } from './ads-summary/awareness';
import { useCampaignDetailsStore } from './ads-summary/campaignDetails';
import { useAdsSummaryPerformanceStore } from './ads-summary/performanse';
import { useNewBrandProtectionStore } from '@/store/brand-protection/main';
import { useAdsSummaryEfficienciesStore } from './ads-summary/efficiencies';
import { usePerformanceSummaryStore } from './ads-summary/performanceSummary';

export const useAbortController = defineStore('abortController', {
  state: () => ({}),
  actions: {
    abortAllRequests() {
      const reviewStore = useReviewsStore();
      const inventoryStore = useInventoryStore();
      const alertStore = useProductAlertsStore();
      const funnelLevelStore = useFunnelLevelStore();
      const brandStore = useNewBrandProtectionStore();
      const productReturnsStore = useProductReturnsStore();
      const awarenessStore = useAdsSummaryAwarenessStore();
      const campaignDetailsStore = useCampaignDetailsStore();
      const salesAndTrafficStore = useSalesAndTrafficStore();
      const performanceStore = useAdsSummaryPerformanceStore();
      const efficienciesStore = useAdsSummaryEfficienciesStore();
      const performanceSummaryStore = usePerformanceSummaryStore();
      const productPerformanceStore = useProductPerformanceStore();

      // All requests
      brandStore.abortRequestsBrandStore();
      reviewStore.abortRequestsReviewStore();
      inventoryStore.abortRequestsInventoryStore();
      awarenessStore.abortRequestsAwarenessStore();
      performanceStore.abortRequestsPerformanceStore();
      funnelLevelStore.abortRequestsFunnelLevelStore();
      efficienciesStore.abortRequestsEfficienciesStore();
      productReturnsStore.abortRequestsProductReturnsStore();
      salesAndTrafficStore.abortRequestsSalesAndTrafficStore();
      performanceSummaryStore.abortRequestsPerformanceSummaryStore();

      // All table requests
      alertStore.abortNoFNSKUTable();
      reviewStore.abortTableRequest();
      alertStore.abortAmazonFBATable();
      alertStore.abortNoShelfLifeTable();
      awarenessStore.abortTableRequest();
      alertStore.abortNoMSRPorCOGSTable();
      alertStore.abortNotInNetsuiteTable();
      performanceStore.abortTableRequest();
      funnelLevelStore.abortTableRequest();
      efficienciesStore.abortTableRequest();
      reviewStore.abortTableRatingRequest();
      productPerformanceStore.abortLowTable();
      productReturnsStore.abortTableRequest();
      productPerformanceStore.abortHighTable();
      alertStore.abortNoPrepDescriptionsTable();
      alertStore.abortTransparencyEnabledTable();
      inventoryStore.abortInventoryTableRequest();
      performanceSummaryStore.abortTableRequest();
      campaignDetailsStore.abortTableTypeRequest();
      salesAndTrafficStore.abortSalesTableRequest();
      productReturnsStore.abortReasonTableRequest();
      campaignDetailsStore.abortTableTargetRequest();
      salesAndTrafficStore.abortTopProductsRequest();
      campaignDetailsStore.abortTableCategoriesRequest();
    },
  },
});
