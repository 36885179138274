<script setup lang="ts">
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref, computed, watch } from "vue";

import { useStore } from "@/store/main";
import type { Brand } from "@/store/main";
import { useFiltersStore } from "@/store/filters";
import { useAbortController } from "@/store/abortController";
import type {
  FilterBrandGroupComputed,
  FilterBrandList,
  FilterBrandOption,
} from "@/types/filters";

import CommonMultiSelect from "@/components/common/CommonMultiSelect.vue";

type Props = {
  defaultValue: string;
};

const route = useRoute();
const store = useStore();
const router = useRouter();
const filterStore = useFiltersStore();
const abortController = useAbortController();

const props = defineProps<Props>();
const emit = defineEmits(["valueUpdate"]);

const chooseValues = ref<Brand[]>([]);
const arrayValues = ref<string[]>([]);

const defineValues = function () {
  arrayValues.value = [];
  if (!chooseValues.value.length) {
    chooseValues.value = [optionsList.value[0].BRANDS[0]];
  }
  chooseValues.value.forEach((el: Brand) => {
    if (el.BRAND_SHORT_NAME) {
      arrayValues.value.push(el["BRAND_SHORT_NAME"]);
    }
  });

  const difference = _.xor(arrayValues.value, filterStore.brands);

  if (difference.length) {
    abortController.abortAllRequests();
    emit("valueUpdate", arrayValues.value);
  }
};

const onSelect = function (value: Brand | Brand[]) {
  if (Array.isArray(value)) {
    value.forEach((el) => {
      checkSelectedBrand(el);
    });
  } else if (!Array.isArray(value)) {
    checkSelectedBrand(value);
  }
};

const onGroupRemove = function (value: FilterBrandOption[]) {
  if (value?.length > 0) {
    value.forEach((el) => {
      if (el.isInGroup) {
        chooseValues.value.findIndex((item) => {
          if (item?.BRAND_NAME === el?.BRAND_NAME) {
            chooseValues.value.splice(chooseValues.value.indexOf(item), 1);
          }
        });
      }
    });
  }
};

const checkSelectedBrand = function (value: Brand) {
  if (value.BRAND_NAME.toLowerCase() === "all") {
    chooseValues.value = [value];
  } else {
    chooseValues.value.find(
      (el: any) => el.BRAND_NAME.toLowerCase() === "all",
    ) && chooseValues.value.splice(0, 1);
  }
};

const checkIfBrandSelected = (data: FilterBrandOption) => {
  return !!chooseValues.value.find(
    (item) => item.BRAND_SHORT_NAME === data.BRAND_SHORT_NAME,
  );
};

const optionsList = computed<FilterBrandList[]>(() => {
  const brandsWithoutGroups: Brand[] = [];
  const groupsOfBrands: any[] = store.brandGroups.map((group: any) => {
    for (let i = 0; i < group.BRANDS.length; i++) {
      group.BRANDS[i].isInGroup = true;
      group.BRANDS[i].groupName = group.PARENT_BRAND_NAME || "";
      if (group.BRANDS.length === i + 1) {
        group.BRANDS[i].isLastInGroup = true;
      }
    }
    return group;
  });
  store.brandList.forEach((brand: any) => {
    const group = groupsOfBrands.find((group) =>
      group.BRANDS?.find(
        (item: Brand) => item.BRAND_SHORT_NAME === brand.BRAND_SHORT_NAME,
      ),
    );
    if (!group && !brand.BRANDS) {
      brandsWithoutGroups.push(brand as Brand);
    }
  });

  return [
    {
      PARENT_BRAND_NAME: "without_groups",
      isHide: true,
      BRANDS: brandsWithoutGroups,
    },
    ...groupsOfBrands,
  ];
});

const groupsOfBrands = computed<FilterBrandGroupComputed[]>(() =>
  store.brandGroups.map((group) => ({
    group: group.PARENT_BRAND_NAME,
    brands: group.BRANDS.map((brand) => brand.BRAND_NAME),
  })),
);

const selectedTags = ({ values }: { values: Brand[] }) => {
  const result = [];
  const brandsWithoutGroup = values
    .filter(
      (el) =>
        groupsOfBrands.value.filter((item) =>
          item.brands.includes(el.BRAND_NAME),
        ).length === 0,
    )
    .map((el) => ({
      name: el.BRAND_NAME,
      isGroup: false,
    }));
  const brandsWithGroup = values
    .filter((el) =>
      groupsOfBrands.value.filter(
        (item) => !item.brands.includes(el.BRAND_NAME),
      ),
    )
    .map((el) => el.BRAND_NAME);

  result.push(...brandsWithoutGroup);

  groupsOfBrands.value.map((el) => {
    if (el.brands.every((item) => brandsWithGroup.includes(item))) {
      result.push({ name: `${el.group} group`, isGroup: true });
    } else {
      const brands = el.brands
        .filter((item) => brandsWithGroup.includes(item))
        .map((el) => ({
          name: el,
          isGroup: false,
        }));
      result.push(...brands);
    }
  });

  return result;
};

watch(
  () => filterStore.brands,
  () => {
    const brandsToString = filterStore.brands.join(",");

    if (brandsToString.length) {
      router.push({ query: { ...route.query, brands: brandsToString } });
    }
    const brandArr = props.defaultValue.split(",");

    if (brandArr.length) {
      chooseValues.value = [];

      if (brandArr.length === 1 && brandArr[0] === "") {
        chooseValues.value = [optionsList.value[0].BRANDS[0]];
        return;
      }

      brandArr.forEach((el) => {
        const foundBrand = store.brands.find(
          (item) => item["BRAND_SHORT_NAME"] === el,
        );
        chooseValues.value.push(foundBrand as Brand);
      });
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (route.query.brands) return;
  chooseValues.value = [optionsList.value[0].BRANDS[0]];
});
</script>

<template>
  <div class="flex flex-col min-w-[300px]">
    <div v-if="!!store.brands.length">
      <CommonMultiSelect
        v-model="chooseValues"
        :options="optionsList"
        label="BRAND_NAME"
        @select="onSelect"
        @remove="onGroupRemove"
        @close="defineValues"
        :multiple="true"
        :clear-on-select="false"
        :close-on-select="false"
        track-by="BRAND_NAME"
        :allowEmpty="false"
        :group-select="true"
        group-label="PARENT_BRAND_NAME"
        group-values="BRANDS"
      >
        <template v-slot:selection="selection">
          <div class="flex w-full align-center min-h-[26px]">
            <div
              v-for="(item, index) in selectedTags(selection)"
              :key="index"
              class="flex justify-center items-center mr-1 py-1 px-2 rounded-full text-mainText text-[14px] font-normal border-[1px] border-gray-200 dark:border-secondaryText"
              :class="{ 'border-none hidden': index >= 2 }"
            >
              <div class="leading-none max-w-full flex-initial cursor-pointer">
                {{ item.name }}
              </div>
            </div>
            <div
              v-if="selectedTags(selection).length > 2"
              class="flex items-center ml-auto text-mainText"
            >
              +{{ selectedTags(selection).length - 2 }} More
            </div>
          </div>
        </template>
        <template v-slot:option="data">
          <div
            v-if="data.option.$isLabel"
            :class="
              data.option.$groupLabel === 'without_groups'
                ? 'parent-hidden'
                : ''
            "
          >
            <span>
              {{
                data.option.$groupLabel === "without_groups"
                  ? `All without groups`
                  : `${data.option.$groupLabel} group`
              }}
            </span>
          </div>
          <div
            class="flex w-full justify-between items-center text-[14px] font-medium"
            :class="
              data.option?.isInGroup && data.option?.isLastInGroup
                ? 'group_brand last_in_group'
                : data.option?.isInGroup
                  ? 'group_brand'
                  : ''
            "
            v-else
          >
            {{ data.option.BRAND_NAME }}
          </div>
        </template>
      </CommonMultiSelect>
    </div>
  </div>
</template>
