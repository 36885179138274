import axios from "@/utils/axios";

import type {
  BrandSalesData,
  TopProductsTableAPI,
  KpiTotal,
} from "@/types/salesAndTraffic/types";
import type { requestParams, PaginatedWrapper } from "@/types/requests";

export const getBrandSales = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<BrandSalesData[]>>(
    "/sales",
    params,
    options,
  );
};
export const getBrandsKPI = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post("/sales/kpi", params, options);
};
export const getTotalValues = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<KpiTotal>("/sales/kpi/totals", params, options);
};

export const getTopProducts = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<TopProductsTableAPI>(
    "/sales/brand-products",
    params,
    options,
  );
};
