import axios from "@/utils/axios";

import type { requestParams, PaginatedWrapper } from "@/types/requests";
import type { ProductPerformanceTableData } from "@/types/productPerformance";

export const getPerformanceTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<ProductPerformanceTableData[]>>(
    "/product-performance/table",
    params,
    options,
  );
};
