import { defineStore } from 'pinia';

import type { TableRow } from '@/types/table';
import type { requestParams } from '@/types/requests';
import {
  getChartFunnelLevel as getChartFunnelLevelApi,
  getKpiFunnelLevel as getKpiFunnelLevelApi,
  getKpiTotalFunnelLevel as getKpiTotalFunnelLevelApi,
  getTableFunnelLevel as getTableFunnelLevelApi,
} from '@/api/adsSummary';
import type {
  FunnelLevelStore,
  FunnelLevelOptions,
  FunnelLevelCalculatedKPI,
  KPIValues,
  TotalKPICalculated,
  FunnelLevelCalculatedTable,
  FunnelLevelChartCalcualted,
} from '@/types/adsSummary/funnelLevel';
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from '@/utils/createAbortControllers';

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const useFunnelLevelStore = defineStore('funnelLevel', {
  state: (): FunnelLevelStore => ({
    selectedOption: 'REVENUE',
    chartFunnelLevel: [],
    funnelLevelKpi: null,
    funnelLevelKpiTotal: null,
    tableColumnHelperText: '',
    funnelLevelTableData: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    WoWKpiData: null,
    MoMKpiData: null,
    YoYKpiData: null,
    PoPKpiData: null,
    isFunnelLevelLoader: true,
    isFunnelLevelKPILoader: true,
    isFunnelLevelChartLoader: true,
    isFunnelLevelTableLoader: true,
    isFunnelLevelTableTotalLoader: true,
  }),

  actions: {
    abortRequestsFunnelLevelStore() {
      this.cleanStore();
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortTableRequest() {
      tableController = replaceSingleController(tableController);
    },

    getChartFunnelLevel(params: requestParams) {
      this.isFunnelLevelLoader = true;
      this.isFunnelLevelChartLoader = true;
      getChartFunnelLevelApi(params, {
        signal: getAbortSignal(controllers),
      })
        .then(({ data }) => {
          this.chartFunnelLevel = data;
        })
        .catch((e) => {})
        .finally(() => {
          this.isFunnelLevelLoader = false;
          this.isFunnelLevelChartLoader = false;
        });
    },

    getKpiFunnelLevel(
      params: requestParams,
      type?: 'WoWKpiData' | 'MoMKpiData' | 'YoYKpiData' | 'PoPKpiData',
    ) {
      return getKpiFunnelLevelApi(params, {
        signal: getAbortSignal(controllers),
      })
        .then(({ data }) => {
          if (type) {
            this[type] = null;
            this[type] = data;
          } else {
            return (this.funnelLevelKpi = data);
          }
        })
        .catch((e) => {});
    },

    getKpiTotalFunnelLevel(params: requestParams) {
      return getKpiTotalFunnelLevelApi(params, {
        signal: getAbortSignal(controllers),
      })
        .then(({ data }) => {
          this.funnelLevelKpiTotal = data;
        })
        .catch((e) => {});
    },

    updateKpisLoader(value: boolean) {
      this.isFunnelLevelKPILoader = value;
    },

    getTableFunnelLevel(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isFunnelLevelTableTotalLoader = true;
      } else {
        this.isFunnelLevelTableLoader = true;
      }
      getTableFunnelLevelApi(params, {
        signal: tableController.signal,
      })
        .then(({ data }) => {
          if (params.limit === 0) {
            callback && callback(data.data);
          } else {
            this.funnelLevelTableData.data = data.data;
            this.funnelLevelTableData.pagination = data.pagination;
          }
          this.isFunnelLevelTableLoader = false;
          this.isFunnelLevelTableTotalLoader = false;
        })
        .catch((err: any) => {
          const errorData = JSON.parse(err?.message);
          this.isFunnelLevelTableLoader = false;
          this.isFunnelLevelTableTotalLoader = false;
          if (errorData.status === 'canceled') {
            this.isFunnelLevelTableLoader = true;
          }
        })
        .finally(() => {
          this.isFunnelLevelTableLoader = false;
          this.isFunnelLevelTableTotalLoader = false;
        });
    },

    setOption(value: FunnelLevelOptions) {
      this.selectedOption = value;
    },
    setTableColumnHelperText(value: string) {
      this.tableColumnHelperText = value;
    },

    cleanStore() {
      this.chartFunnelLevel = [];
      this.funnelLevelKpi = null;
      this.funnelLevelKpiTotal = null;
      this.funnelLevelTableData = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
      this.WoWKpiData = null;
      this.MoMKpiData = null;
      this.YoYKpiData = null;
      this.PoPKpiData = null;
      this.isFunnelLevelLoader = true;
      this.isFunnelLevelKPILoader = true;
      this.isFunnelLevelChartLoader = true;
      this.isFunnelLevelTableLoader = true;
      this.isFunnelLevelTableTotalLoader = true;
    },
  },
  getters: {
    chartData(): FunnelLevelChartCalcualted[] | undefined {
      if (!this.chartFunnelLevel.length) return;
      const filteredArray = this.chartFunnelLevel.map((el) => ({
        AWARENESS: el?.[`${this.selectedOption}_AWARENESS`] || '',
        CONSIDERATION: el?.[`${this.selectedOption}_CONSIDERATION`] || '',
        CONVERSION: el?.[`${this.selectedOption}_CONVERSION`] || '',
        LOYALTY: el?.[`${this.selectedOption}_LOYALTY`] || '',
        DATE: el.DATE,
      }));
      return filteredArray as FunnelLevelChartCalcualted[];
    },

    awarenessKpiValue(): FunnelLevelCalculatedKPI {
      const values: KPIValues[] = [
        {
          name: 'PoP',
          value: {
            difference: this.PoPKpiData?.[`${this.selectedOption}_AWARENESS_DIFFERENCE`] || '',
            ratio_percentage:
              this.PoPKpiData?.[`${this.selectedOption}_AWARENESS_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'WoW',
          value: {
            difference: this.WoWKpiData?.[`${this.selectedOption}_AWARENESS_DIFFERENCE`] || '',
            ratio_percentage:
              this.WoWKpiData?.[`${this.selectedOption}_AWARENESS_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'MoM',
          value: {
            difference: this.MoMKpiData?.[`${this.selectedOption}_AWARENESS_DIFFERENCE`] || '',
            ratio_percentage:
              this.MoMKpiData?.[`${this.selectedOption}_AWARENESS_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'YoY',
          value: {
            difference: this.YoYKpiData?.[`${this.selectedOption}_AWARENESS_DIFFERENCE`] || '',
            ratio_percentage:
              this.YoYKpiData?.[`${this.selectedOption}_AWARENESS_RATIO_PERCENTAGE`] || '',
          },
        },
      ];

      return {
        name: 'Awareness',
        values: values,
      };
    },

    considerationKpiValue(): FunnelLevelCalculatedKPI {
      const values: KPIValues[] = [
        {
          name: 'PoP',
          value: {
            difference: this.PoPKpiData?.[`${this.selectedOption}_CONSIDERATION_DIFFERENCE`] || '',
            ratio_percentage:
              this.PoPKpiData?.[`${this.selectedOption}_CONSIDERATION_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'WoW',
          value: {
            difference: this.WoWKpiData?.[`${this.selectedOption}_CONSIDERATION_DIFFERENCE`] || '',
            ratio_percentage:
              this.WoWKpiData?.[`${this.selectedOption}_CONSIDERATION_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'MoM',
          value: {
            difference: this.MoMKpiData?.[`${this.selectedOption}_CONSIDERATION_DIFFERENCE`] || '',
            ratio_percentage:
              this.MoMKpiData?.[`${this.selectedOption}_CONSIDERATION_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'YoY',
          value: {
            difference: this.YoYKpiData?.[`${this.selectedOption}_CONSIDERATION_DIFFERENCE`] || '',
            ratio_percentage:
              this.YoYKpiData?.[`${this.selectedOption}_CONSIDERATION_RATIO_PERCENTAGE`] || '',
          },
        },
      ];

      return {
        name: 'Consideration',
        values: values,
      };
    },
    conversionKpiValue(): FunnelLevelCalculatedKPI {
      const values: KPIValues[] = [
        {
          name: 'PoP',
          value: {
            difference: this.PoPKpiData?.[`${this.selectedOption}_CONVERSION_DIFFERENCE`] || '',
            ratio_percentage:
              this.PoPKpiData?.[`${this.selectedOption}_CONVERSION_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'WoW',
          value: {
            difference: this.WoWKpiData?.[`${this.selectedOption}_CONVERSION_DIFFERENCE`] || '',
            ratio_percentage:
              this.WoWKpiData?.[`${this.selectedOption}_CONVERSION_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'MoM',
          value: {
            difference: this.MoMKpiData?.[`${this.selectedOption}_CONVERSION_DIFFERENCE`] || '',
            ratio_percentage:
              this.MoMKpiData?.[`${this.selectedOption}_CONVERSION_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'YoY',
          value: {
            difference: this.YoYKpiData?.[`${this.selectedOption}_CONVERSION_DIFFERENCE`] || '',
            ratio_percentage:
              this.YoYKpiData?.[`${this.selectedOption}_CONVERSION_RATIO_PERCENTAGE`] || '',
          },
        },
      ];

      return {
        name: 'Consideration',
        values: values,
      };
    },
    loyaltyKpiValue(): FunnelLevelCalculatedKPI {
      const values: KPIValues[] = [
        {
          name: 'PoP',
          value: {
            difference: this.PoPKpiData?.[`${this.selectedOption}_LOYALTY_DIFFERENCE`] || '',
            ratio_percentage:
              this.PoPKpiData?.[`${this.selectedOption}_LOYALTY_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'WoW',
          value: {
            difference: this.WoWKpiData?.[`${this.selectedOption}_LOYALTY_DIFFERENCE`] || '',
            ratio_percentage:
              this.WoWKpiData?.[`${this.selectedOption}_LOYALTY_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'MoM',
          value: {
            difference: this.MoMKpiData?.[`${this.selectedOption}_LOYALTY_DIFFERENCE`] || '',
            ratio_percentage:
              this.MoMKpiData?.[`${this.selectedOption}_LOYALTY_RATIO_PERCENTAGE`] || '',
          },
        },
        {
          name: 'YoY',
          value: {
            difference: this.YoYKpiData?.[`${this.selectedOption}_LOYALTY_DIFFERENCE`] || '',
            ratio_percentage:
              this.YoYKpiData?.[`${this.selectedOption}_LOYALTY_RATIO_PERCENTAGE`] || '',
          },
        },
      ];

      return {
        name: 'Consideration',
        values: values,
      };
    },

    totalKpiValue(): TotalKPICalculated {
      return {
        AWARENESS: String(this.funnelLevelKpiTotal?.[`${this.selectedOption}_AWARENESS`]),
        CONSIDERATION: String(this.funnelLevelKpiTotal?.[`${this.selectedOption}_CONSIDERATION`]),
        CONVERSION: String(this.funnelLevelKpiTotal?.[`${this.selectedOption}_CONVERSION`]),
        LOYALTY: String(this.funnelLevelKpiTotal?.[`${this.selectedOption}_LOYALTY`]),
      };
    },

    tableValue(): FunnelLevelCalculatedTable[] {
      return this.funnelLevelTableData?.data?.map((el: any) => ({
        DATE: el.DATE,
        PRODUCT_NAME: el.PRODUCT_NAME,
        ASIN: el.ASIN,
        SKU: el.SKU,
        AWARENESS: el?.[`${this.selectedOption}_AWARENESS`],
        CONSIDERATION: el?.[`${this.selectedOption}_CONSIDERATION`],
        CONVERSION: el?.[`${this.selectedOption}_CONVERSION`],
        LOYALTY: el?.[`${this.selectedOption}_LOYALTY`],
      }));
    },

    collapseLoader(): boolean {
      if (this.isFunnelLevelLoader || this.isFunnelLevelChartLoader) {
        return false;
      } else return true;
    },
  },
});
