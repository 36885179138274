import axios from "@/utils/axios";
import { createUserFilter, userFilter } from "@/types/filters";

export const getUserFiltersApi = () => {
  return axios.get<userFilter[]>("/user-filters");
};

export const createUserFilterApi = (data: createUserFilter) => {
  return axios.post("/user-filters/save-filter", data);
};

export const deleteUserFilterApi = (id: string) => {
  return axios.delete(`/user-filters/${id}`);
};

export const setFavoriteFilterApi = (id: string, favorite: boolean) => {
  return axios.patch("/user-filters/set-favorite", { id, favorite });
};
