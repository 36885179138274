import axios from "@/utils/axios";

import type { FunnelLevelItem } from "@/types/adsSummary/funnelLevel";
import type { requestParams, PaginatedWrapper } from "@/types/requests";
import type {
  ChartadsPerformance,
  AdsPerformanceKPIValue,
  AdsPerformanceTotal,
  AdsPerformanceTable,
} from "@/types/adsSummary/adsPerformance";
import type {
  ChartAdsAwareness,
  AdsAwarenessTotal,
  AdsAwarenessKPIValue,
  AdsAwarenessTable,
} from "@/types/adsSummary/adsAwareness";
import type {
  ChartAdsEfficiencies,
  AdsEfficienciesTotal,
  AdsEfficienciesKPIValue,
  AdsEfficienciesTable,
} from "@/types/adsSummary/adsEfficiencies";
import type {
  CampanyType,
  CampanyTargeting,
  ProductCategories,
} from "@/types/adsSummary/campanyDetails";

import type { PerformanceSummaryTableRow } from "@/types/adsSummary/performanceSummary";

export const getChartAdsPerformance = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ChartadsPerformance[]>(
    "/ads-summary/ads-performance/chart",
    params,
    options,
  );
};

export const getKpiAdsPerformance = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<AdsPerformanceKPIValue>(
    "/ads-summary/ads-performance/kpi",
    params,
    options,
  );
};

export const getKpiTotalAdsPerformance = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<AdsPerformanceTotal>(
    "/ads-summary/ads-performance/kpi/totals",
    params,
    options,
  );
};

export const getAdsPerformanceTable = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<AdsPerformanceTable[]>>(
    "/ads-summary/ads-performance/table",
    params,
    options,
  );
};

export const getChartAdsAwareness = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ChartAdsAwareness[]>(
    "/ads-summary/ads-awareness/chart",
    params,
    options,
  );
};

export const getKpiAdsAwareness = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<AdsAwarenessKPIValue>(
    "/ads-summary/ads-awareness/kpi",
    params,
    options,
  );
};

export const getKpiTotalAdsAwareness = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<AdsAwarenessTotal>(
    "/ads-summary/ads-awareness/kpi/totals",
    params,
    options,
  );
};

export const getAdsAwarenessTable = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<AdsAwarenessTable[]>>(
    "/ads-summary/ads-awareness/table",
    params,
    options,
  );
};

export const getChartAdsEfficiencies = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<ChartAdsEfficiencies[]>(
    "/ads-summary/ads-efficiencies/chart",
    params,
    options,
  );
};

export const getKpiAdsEfficiencies = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<AdsEfficienciesKPIValue>(
    "/ads-summary/ads-efficiencies/kpi",
    params,
    options,
  );
};

export const getKpiTotalAdsEfficiencies = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<AdsEfficienciesTotal>(
    "/ads-summary/ads-efficiencies/kpi/totals",
    params,
    options,
  );
};

export const getAdsEfficienciesTable = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<AdsEfficienciesTable[]>>(
    "/ads-summary/ads-efficiencies/table",
    params,
    options,
  );
};

export const getPerformanceSummary = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<PerformanceSummaryTableRow[]>>(
    "/ads-summary/performance-summary/table",
    params,
    options,
  );
};

export const getCampaignTypesTable = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<CampanyType[]>>(
    "/ads-summary/campaign-details/campaign-types-table",
    params,
    options,
  );
};

export const getCampaignTargetingTable = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<CampanyTargeting[]>>(
    "/ads-summary/campaign-details/campaign-targeting-table",
    params,
    options,
  );
};

export const getProductCategoriesTable = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<ProductCategories[]>>(
    "/ads-summary/campaign-details/product-categories-table",
    params,
    options,
  );
};

export const getChartFunnelLevel = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<FunnelLevelItem[]>(
    "/ads-summary/funnel-level/chart",
    params,
    options,
  );
};

export const getKpiFunnelLevel = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<FunnelLevelItem>(
    "/ads-summary/funnel-level/kpi",
    params,
    options,
  );
};

export const getKpiTotalFunnelLevel = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<FunnelLevelItem>(
    "/ads-summary/funnel-level/kpi/totals",
    params,
    options,
  );
};

export const getTableFunnelLevel = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<FunnelLevelItem[]>>(
    "/ads-summary/funnel-level/table",
    params,
    options,
  );
};
