import { useToast as useToastProvider } from "vue-toastification";

export const useToast = () => {
  const toast = useToastProvider();

  const defaultOptions = {
    timeout: 1500,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.5,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
  };

  const error = (message: string) => {
    return toast.error(message, {
      ...defaultOptions,
      timeout: 5000,
      closeButton: "button",
    });
  };

  const success = (message: string) =>
    toast.success(message, {
      ...defaultOptions,
      closeButton: "button",
    });

  const info = (message: string) =>
    toast.info(message, {
      ...defaultOptions,
      timeout: 3000,
      closeButton: "button",
    });

  const warn = (message: string) =>
    toast.warning(message, {
      ...defaultOptions,
      timeout: 5000,
      closeButton: "button",
    });

  return {
    toast,
    defaultOptions,
    danger: error,
    success,
    info,
    warn,
    error,
  };
};
