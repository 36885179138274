<script setup lang="ts">
// Serhii - Add slot for title + think about withoutPadding, separate card for charts with skeleton
import { computed } from "vue";

import EmptyData from "@/assets/images/EmptyData.vue";
import BaseLoader from "@/components/common/CommonLoader.vue";
import BarChartSkeleton from "@/components/SkeletonPresets/BarChartSkeleton.vue";

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    showShadow?: boolean;
    emptyData?: boolean;
    loading?: boolean;
    noDataFoundText?: string;
    chart?: boolean;
    withoutPadding?: boolean;
    optionsForChartSkeleton?: number;
  }>(),
  {
    showShadow: true,
    noDataFoundText: "Unfortunately, there are no data available.",
    optionsForChartSkeleton: 3,
  },
);

const isHaveSupportText = computed(() => props.title || props.description);
</script>

<template>
  <div
    class="md:rounded-md align-center h-fit transition-all duration-500"
    :class="{
      'shadow-lg dark:shadow-slate-900/50': props.showShadow,
      'bg-cardBg': !props.chart,
      'bg-chartBg': props.chart,
      'p-4 md:p-30': !props.withoutPadding,
    }"
  >
    <div v-if="isHaveSupportText">
      <div
        v-if="title"
        class="font-normal text-mainText text-[16px] flex gap-1 items-center"
      >
        <div>{{ title }}</div>
      </div>
      <div
        v-if="description"
        class="font-light text-[0.7rem] pb-2 text-secondaryText"
      >
        {{ description }}
      </div>
    </div>
    <div
      v-if="props.loading"
      :class="{
        'flex justify-center items-center h-full': !$slots.loading,
      }"
      class="h-full w-full"
    >
      <BaseLoader v-if="!$slots.loading && !props.chart" />
      <div v-if="props.chart">
        <BarChartSkeleton />
      </div>
      <slot name="loading" />
    </div>
    <div class="text-mainText" v-if="!props.emptyData && !props.loading">
      <slot />
    </div>
    <div
      v-if="!props.loading && props.emptyData"
      class="flex flex-col justify-center items-center h-full"
    >
      <EmptyData />
      <span class="text-[16px] text-mainText"> {{ noDataFoundText }} </span>
    </div>
  </div>
</template>
