import { RouteNames } from "@/types/router";

export default [
  {
    path: "/login",
    name: RouteNames.LOGIN,
    component: () => import("@/views/Auth/LoginPage.vue"),
    meta: {
      layout: "auth",
      withoutAuth: true,
    },
  },
  {
    path: "/password-forgot",
    name: RouteNames.FORGET_PASSWORD,
    meta: {
      layout: "auth",
      withoutAuth: true,
    },

    component: () => import("@/views/Auth/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: RouteNames.REST_PASSWORD,
    meta: {
      layout: "auth",
      withoutAuth: true,
    },
    component: () => import("@/views/Auth/ResetPassword.vue"),
  },
];
