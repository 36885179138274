import { defineStore } from 'pinia';

import type { TableRow } from '@/types/table';
import type { requestParams } from '@/types/requests';
import type { InventoryStoreType } from '@/types/inventory';
import { getInventoryKpiApi, getInventoryTableApi } from '@/api/inventory';
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from '@/utils/createAbortControllers';

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const useInventoryStore = defineStore('inventory', {
  state: (): InventoryStoreType => ({
    inventoryKpis: null,
    tableData: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    isKpiLoader: true,
    isTableLoader: true,
    isTableTotalsLoader: true,
  }),

  actions: {
    abortRequestsInventoryStore() {
      this.cleanStore();
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortInventoryTableRequest() {
      tableController = replaceSingleController(tableController);
    },

    async getInventoryKpi(params: requestParams) {
      this.isKpiLoader = true;
      try {
        const { data } = await getInventoryKpiApi(params, {
          signal: getAbortSignal(controllers),
        });

        this.inventoryKpis = data;
        this.isKpiLoader = false;
      } catch (e) {
        this.isKpiLoader = false;
      }
    },

    async getInventoryTable(params: requestParams, callback?: (data: TableRow[]) => void) {
      if (params.limit === 0) {
        this.isTableTotalsLoader = true;
      } else {
        this.isTableLoader = true;
      }
      try {
        const { data } = await getInventoryTableApi(params, {
          signal: tableController.signal,
        });
        if (params.limit === 0) {
          callback && callback(data.data);
        } else {
          this.tableData.data = data.data;
          this.tableData.pagination = data.pagination;
        }
        this.isTableLoader = false;
        this.isTableTotalsLoader = false;
      } catch (error: any) {
        const errorData = JSON.parse(error?.message);
        this.isTableLoader = false;
        this.isTableTotalsLoader = false;
        if (errorData.status === 'canceled') {
          this.isTableLoader = true;
        }
      }
    },

    cleanStore() {
      this.inventoryKpis = null;
      this.tableData = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
    },
  },

  getters: {},
});
