<script lang="ts" setup>
import CommonLoader from "@/components/common/CommonLoader.vue";

const props = withDefaults(
  defineProps<{
    text: string;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    loader?: boolean;
    customColor?: boolean;
    height?: string;
    properCase?: boolean;
  }>(),
  {
    type: "button",
    disabled: false,
    height: "40px",
    properCase: true,
  },
);
</script>

<template>
  <button
    :type="props.type"
    class="common-button"
    :class="{
      'bg-primary hover:bg-[#0E61D5]': !props.customColor,
      capitalize: props.properCase,
    }"
    :style="{ height: props.height }"
    :disabled="props.disabled || props.loader"
    v-bind="$attrs"
  >
    <span
      v-if="$slots.iconLeft || props.loader"
      class="px-2 flex items-center justify-center"
    >
      <CommonLoader v-if="props.loader" class="w-[20px]" />

      <slot v-else name="iconLeft" />
    </span>
    <div class="flex gap-[3px] justify-center items-center">
      <span>
        {{ props.text }}
      </span>
    </div>
    <span v-if="$slots.iconRight" class="pl-[12px]">
      <slot name="iconRight" />
    </span>
  </button>
</template>

<style lang="scss">
.common-button {
  @apply flex justify-center transition-all w-full duration-500 px-3 py-2 items-center rounded-md disabled:cursor-not-allowed disabled:bg-[#7c818aa8] disabled:text-white hover:shadow-lg font-light  text-white  leading-[19px] shadow-md dark:shadow-slate-900/50;
}
</style>
