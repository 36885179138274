<script setup lang="ts">
import { computed } from "vue";

type Props = {
  modelValue?: string | number | boolean | any;
  id?: string;
  label?: string;
  value?: string | number | boolean | any;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: "update:modelValue", value: string | number | boolean | any): boolean;
}>();

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    return emit("update:modelValue", value as any | boolean);
  },
});
</script>

<template>
  <div class="flex items-center gap-3">
    <label
      class="text-[14px] text-secondaryText flex items-center gap-2"
      :for="id"
    >
      <input
        type="checkbox"
        class="flex justify-center items-center cursor-pointer w-[15px] h-[15px]"
        v-model="modelValue"
        :id="id"
        v-bind="$attrs"
        :value="value"
      />
      <article v-if="!$slots.label" class="text-secondaryText">
        {{ props.label }}
      </article>
      <article v-if="$slots.label">
        <slot name="label" />
      </article>
    </label>
  </div>
</template>
