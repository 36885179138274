import axios from "@/utils/axios";

interface Param {
  brand?: string[];
}

export const getBrands = () => {
  return axios.get("/brands");
};
export const getChannels = () => {
  return axios.get("/channels");
};
export const getBrandGroups = () => {
  return axios.get("/brands/groups");
};
export const productSearch = (params?: Param) => {
  return axios.post("/products", params);
};

export const getRefreshDate = () => {
  return axios.get("/date/refresh-date");
};
