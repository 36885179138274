<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    name: string;
    customSize?: string;
    onlineDot?: boolean;
  }>(),
  { name: "", customSize: "50" },
);

const getAvatarColor = computed(() => {
  const colorIndex = props.name.charCodeAt(0) % 7;
  const colors = [
    "#3498db",
    "#2ecc71",
    "#e74c3c",
    "#f39c12",
    "#9b59b6",
    "#1abc9c",
    "#e67e22",
  ];
  return colors[colorIndex];
});

const getInitials = computed(() => {
  const names = props.name.split(" ");
  if (names.length > 1) {
    return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
  } else {
    return props.name.charAt(0).toUpperCase();
  }
});
</script>

<template>
  <div
    class="avatar"
    :style="{
      width: props.customSize + 'px',
      height: props.customSize + 'px',
    }"
  >
    <span>{{ getInitials }}</span>
  </div>
</template>

<style scoped>
.avatar {
  @apply text-[20px] text-white flex justify-center items-center rounded-[50%] relative bg-primary;
}

.online_dot {
  @apply bg-green-500 w-[10px] h-[10px] rounded-full absolute right-[5px] bottom-0;
}
</style>
