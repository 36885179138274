import axios from "@/utils/axios";

import type { requestParams, PaginatedWrapper } from "@/types/requests";
import type {
  SellersKPI,
  SellersChart,
  SellersTable,
  LostRevenueKPI,
  LostRevenueChart,
  LostRevenueTable,
  SellersChartBySKU,
  LostRevenueKPITotal,
} from "@/types/brandProtection";

interface Params {
  brand?: string[];
  channel?: string[] | number[];
  granularity?: string | null | undefined;
  period?: object | null | undefined;
}

export const getBrandProtectionActions = (params: Params) => {
  return axios.post("/brand-protection/actions", params);
};
export const getBrandProtectionKpi = (params: Params) => {
  return axios.post("/brand-protection/kpi", params);
};
export const getBrandProtectionStoppedSellers = (params: Params) => {
  return axios.post("/brand-protection/stopped-sellers", params);
};
export const getBrandProtectionProductQuantity = (params: any) => {
  return axios.post("/brand-protection/product-quantity", params);
};
export const getBrandProtectionProductInfo = (params: any) => {
  return axios.post("/brand-protection/product-info", params);
};
export const getBrandProtectionLostRevenue = (params: any) => {
  return axios.post("/brand-protection/lost-revenue", params);
};
export const getBrandProtectionProductLostRevenue = (params: any) => {
  return axios.post("/brand-protection/lost-revenue-by-product", params);
};
export const getSellersInfo = (params?: any) => {
  return axios.post("/brand-protection/sellers-info", params);
};

// NEW REQUESTS

export const getBrandProtectionSellersChart = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<SellersChart[]>(
    "/brand-protection/sellers/chart",
    params,
    options,
  );
};

export const getBrandProtectionSellersKpi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<SellersKPI>(
    "/brand-protection/sellers/kpi",
    params,
    options,
  );
};

export const getBrandProtectionSellersBySKUKpi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<SellersChartBySKU>(
    "/brand-protection/sellers/chart-seller-by-sku",
    params,
    options,
  );
};

export const getBrandProtectionSellersTable = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<SellersTable[]>>(
    "/brand-protection/sellers/table",
    params,
    options,
  );
};

export const getBrandProtectionLostRevenueChart = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<LostRevenueChart[]>(
    "/brand-protection/lost-revenue/chart",
    params,
    options,
  );
};

export const getBrandProtectionLostRevenueKPI = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<LostRevenueKPI>(
    "/brand-protection/lost-revenue/kpi",
    params,
    options,
  );
};

export const getBrandProtectionLostRevenueKPITotal = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<LostRevenueKPITotal>(
    "/brand-protection/lost-revenue/kpi/totals",
    params,
    options,
  );
};

export const getBrandProtectionLostRevenueTable = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<LostRevenueTable[]>>(
    "/brand-protection/lost-revenue/table",
    params,
    options,
  );
};

export const getTableBySellerApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post(
    "/brand-protection/sellers/table-by-seller",
    params,
    options,
  );
};

export const getTableByProductApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post(
    "/brand-protection/sellers/table-by-product",
    params,
    options,
  );
};
