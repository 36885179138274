import axios from "@/utils/axios";

import type { requestParams, PaginatedWrapper } from "@/types/requests";
import type {
  noMSRPorCOGSTable,
  notInNetsuiteTable,
  transparencyEnabledTable,
  noShelfLifeTable,
  noFNSKUTable,
  noPrepDescriptionsTable,
  amazonFBATable,
  missingDIMSTable,
} from "@/types/productAlerts";

export const getMSRPorCOGSTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<noMSRPorCOGSTable[]>>(
    "/product-alerts/no-msrp-or-cogs",
    params,
    options,
  );
};

export const getNoFNSKUTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<noFNSKUTable[]>>(
    "/product-alerts/no-fnsku",
    params,
    options,
  );
};

export const getNotInNetsuiteTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<notInNetsuiteTable[]>>(
    "/product-alerts/not-in-netsuite",
    params,
    options,
  );
};

export const getNoShelfLifeTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<noShelfLifeTable[]>>(
    "/product-alerts/no-shelf-life",
    params,
    options,
  );
};

export const getTransparencyEnabledTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<transparencyEnabledTable[]>>(
    "/product-alerts/transparency-enabled",
    params,
    options,
  );
};

export const getNoPrepDescriptionsTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<noPrepDescriptionsTable[]>>(
    "/product-alerts/no-prep-descriptions",
    params,
    options,
  );
};

export const getMissingDimsOrWeghtTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<missingDIMSTable[]>>(
    "/product-alerts/missing-dims-or-weight",
    params,
    options,
  );
};

export const getAmazonFBAInactiveTableApi = (
  params: requestParams,
  options?: { signal: AbortSignal },
) => {
  return axios.post<PaginatedWrapper<amazonFBATable[]>>(
    "/product-alerts/amazon-fba-inactive",
    params,
    options,
  );
};
